[data-style="2"] {

  /*
   Cards are surfaces that display content and actions on a single topic.

   They should be easy to scan for relevant and actionable information. Elements, like text and images, should be placed on them in a way that clearly indicates hierarchy.

   Main definition is in the `mixins/card`.
  */

.card {
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
  padding: var(--spacing-xxl);
  overflow: hidden;
  border-radius: 4px;
  font-size: calc(0msu * 1rem);
  background-color: var(--clr-white);
  position: relative;

  @media(--hover) {
    &:hover {
      box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.2);
    }
  }

  &--no-shadow {
    box-shadow: none;

    @media(--hover) {
      &:hover {
        box-shadow: none;
      }
    }
  }

  &__content p {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-all;
  }

  &--notpadded {
    padding-left: 0;
    padding-right: 0;

    .card__heading {
      padding-left: var(--spacing-xxl);
      padding-right: var(--spacing-xxl);
    }
  }

  &--empty {
    background-color: var(--clr-athens-gray);
    color: var(--clr-silver);
    border: 1px solid var(--clr-gallery);

    @media(--hover) {
      &:hover {
        box-shadow: none;
      }
    }
  }

  &__actions {
    color: var(--clr-silver);
    display: flex;

    @extend %actions-icon;

    @media(--hover) {
      > *:hover {
        color: var(--clr-text);
      }
    }

    .selected {
      color: var(--clr-favorite);
    }
  }

  &__heading {
    font-size: calc(3msu * 1rem);
    font-weight: 700;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: var(--spacing-m);

    h2,
    h3,
    h4,
    h5 {
      line-height: 1.1;
    }
  }

  &__heading--toggle {
    margin-bottom: var(--spacing-none) !important;

  }

  &__content {
    .card__content__block {
      max-width: calc(100% - var(--spacing-xxl));
    }

    div.card__content__block:not(:last-child) {
      margin-bottom: var(--spacing-s);
    }

    p {
      display: inline-block;

      span {
        font-weight: 700;
      }
    }

    &.max-height-scroll {
      &--80 {
        max-height: 80px !important;
        overflow: auto;
      }

      &--100 {
        max-height: 100px !important;
        overflow: auto;
      }

      &--135 {
        max-height: 135px !important;
        overflow: auto;
      }
    }
  }

  &__footer {
    line-height: normal;
    width: 100%;
    background-color: transparent;
    padding: var(--spacing-s) var(--spacing-l);
    background-color: inherit;
    border-top: 1px solid rgba(160, 160, 160, 0.2);
  }


  /** Simpler card with just padding */

  &-panel {
    transition: box-shadow .25s;
    padding: 1.5rem 2rem;
    border-radius: var(--base-radius);
    background-color: var(--clr-white);
    font-size: calc(1msu * 1rem);
    margin-top: 1.125rem;
    margin-bottom: 1.125rem;

    @media (--medium) {
      box-shadow: 0 1px 1px 0 rgba(0, 0, 0, .14), 0 1px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
      &:hover {
        box-shadow: 0 4px 5px 0 rgba(0, 0, 0, .14), 0 1px 10px 0 rgba(0, 0, 0, .12), 0 2px 4px -1px rgba(0, 0, 0, .2);
      }
    }
  }
}

.elastic-grid--vertical .card,
.elastic-grid--horizontal-30 .card,
.elastic-grid--horizontal-20 .card,
.elastic-grid--horizontal-12 .card,
&.elastic-grid--vertical .card,
&.elastic-grid--horizontal-30 .card,
&.elastic-grid--horizontal-20 .card,
&.elastic-grid--horizontal-12 .card {
    margin: 0;
}

}

.modal {
  height: 100vh;
  margin: 0;
  z-index: var(--z-index-modal);
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  transition: opacity .15s linear;
  display: none;
  grid-template-areas:
    "modal-heading"
    "modal-message"
    "modal-body";
  grid-template-rows: auto auto 1fr;

  @media (--medium) {
    width: 90%;
    margin: 40px auto;
    height: calc(100vh - 60px);
  }

  &-heading {
    grid-area: modal-heading;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--clr-gallery);
    box-shadow: 0 1px 1px 0 rgba(0,0,0,.14),0 1px 1px -2px rgba(0,0,0,.2), 0 1px 5px 0 rgba(0,0,0,.12);
    border-radius: 3px 3px 0 0;
    padding: var(--spacing-s) var(--spacing-l);

    &__actions {
      display: flex;

      .modal-message-trigger.active i {
        color: var(--clr-alizarin-crimson);
      }
    }

    div {
      cursor: pointer;
      margin-left: var(--spacing-l);
    }

    h3, i {
      padding: 0;
      color: var(--clr-gray);

    }
  }

  &-message {
    background-color: var(--clr-white);
    grid-area: modal-message;

    &__content {
      margin: var(--spacing-xxl) var(--spacing-xxl) var(--spacing-none) var(--spacing-xxl);

      .note:last-child {
        margin-bottom: var(--spacing-none);
      }
    }
  }

  &-body {
    grid-area: modal-body;
    position: relative;
    min-height: 6em;

    &__content {
      height: 100%;
      min-height: 2em;
      padding: var(--spacing-xxl);
      background: var(--clr-white);
      border-radius: 0 0 3px 3px;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      overflow-y: auto;
      overflow-x: hidden;

      h3, h4 {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
      }

      .note {
        margin-bottom: var(--spacing-xxl);
      }

      @media (--medium) {
        max-height: 100% !important;
        height: auto !important;
      }
    }
  }

  &-delete-item {
    font-size: 18px;
    font-weight: 700;
  }

  &--file-preview img {
    display: block;
    margin: var(--spacing-none) auto;
    max-width: 100%;
  }

  &--confirm h3:before, &--error h3:before, &--delete h3:before, .wrapper-confirm-delete h3:before {
    font-family: var(--family-awesome);
  }

  &--confirm h3:before {
    content: "\f059";
    color: var(--clr-action);
    margin-right: var(--spacing-xxs);
  }

  &--error h3:before, &--delete h3:before, .wrapper-confirm-delete h3:before {
    content: "\f06a";
    color: var(--clr-danger);
    margin-right: var(--spacing-xxs);
  }

  &-backdrop {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #000;
    opacity: .7;
    z-index: var(--z-index-modal-backdrop);
    display: none;
    overflow: hidden;
  }

  &-open {
    display: grid !important;
  }

  &-backdrop-open {
    display: block;
  }
}

.search2link {
  .elastic-grid--horizontal-20 {
    grid-column-gap: calc(2*var(--spacing-xl)) !important;
  }

  &__buttons {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: var(--spacing-s);

    a {
      line-height: 34px;
    }
  }

  .card {
    box-shadow: none;

    &__heading h5 {
      margin-bottom: var(--spacing-none);
      padding-bottom: var(--spacing-m) !important;
    }

    &__content {
      padding-top: var(--spacing-m);

      @media(--hover) {
        > table tr:hover {
          background-color: var(--clr-french-pass);
        }
      }

      > table tr:active {
        background-color: var(--clr-french-pass);
      }
    }
  }

  h3, h5 {
    white-space: nowrap;
    font-weight: 400 !important;
  }

  &__items > div {
    max-height: 700px;
    overflow-y: auto;
    overflow-x: hidden;
  }
}

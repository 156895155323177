/* taken from https://raw.githubusercontent.com/suitcss/base/master/lib/base.css */

/**
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * 1. Prevent padding and border from affecting element width
 * https://goo.gl/pYtbK7
 * 2. Change the default font family in all browsers (opinionated)
 */

[data-style="2"],
[data-style="2"]::before,
[data-style="2"]::after {
  box-sizing: border-box; /* 1 */
  font-family: var(--family-sans); /* 2 */
}

[data-style="2"] {
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

[data-style="2"] {

/**
 * Removes the default spacing and border for appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
figure,
p,
pre {
  margin: 0;
}

button {
  background: transparent;
  border: var(--spacing-none);
  padding: var(--spacing-none);
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  border: var(--spacing-none);
  margin: var(--spacing-none);
  padding: var(--spacing-none);
}

iframe {
  border: var(--spacing-none);
}


/**
 * Suppress the focus outline on elements that cannot be accessed via keyboard.
 * This prevents an unwanted focus outline from appearing around elements that
 * might still respond to pointer events.
 */

[tabindex="-1"]:focus {
  outline: none !important;
}

}
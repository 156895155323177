.file-upload {
    .avatar {
      height: 50px !important;
      width: 50px !important;
    }

    .df > *:not(:last-child) {
      margin-right: var(--spacing-m);
    }

    span.file-upload__action {
      white-space: nowrap;
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }

      i {
        margin-right: var(--spacing-xxxs);
      }
    }

    span.file-upload__file-name {
      border: 1px solid var(--clr-gallery);
      padding: var(--spacing-m);
      border-radius: 3px;
      color: var(--clr-gray);
    }

    &.file-upload--small button {
      font-size: .8em;
      padding: var(--spacing-xs) var(--spacing-xl);
    }
}

/* for capture and crop photo modals */
.file-upload--capture img {
  width: 100%;
  border-radius: 3px;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
}

.file-upload--crop {
  display: flex;
  justify-content: space-between;

  .file-upload--crop__big {
    width: 330px;
    border: 6px solid  var(--clr-gallery);
    border-radius: 3px;
  }

  .file-upload--crop__small {
    width: 100px;
    height: 100px; 

    overflow: hidden; /* needed for crop js functionality */
  }

  img {
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.05);
    border-radius: 3px;
  }
}

%actions-icon {
  a, i {
    color: var(--clr-gray);
    @extend %fs2;
    transition: background-color .2s;

    @media(--hover) {
      &:hover {
        color: var(--clr-scorpion);
        text-decoration: none !important;
      }
    }
  }
}

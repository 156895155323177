[data-style="2"] {

.switch {
  position: relative;
}

.switch__checkbox {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
}

.switch__label {
  display: inline-block;
  padding: 0 40px 0 0;
  font-size: 14px;
  
  cursor: pointer;
  
  &:before {
    transition: transform .3s ease-in-out;
    content: "";
    position: absolute;
    right: 1.1rem;
    top: 2px;
    display: block;
    width: .8rem;
    height: .8rem;
    background: #fff;
    border-radius: 50%;
  }
  
  &:after {
    transition: background .3s ease-in-out;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 2rem;
    height: 1.1rem;
    background: var(--clr-gray);
    border-radius: var(--spacing-s);
  }
}

.switch__checkbox:checked + .switch__label {
  &:before {
    transform: translateX(1rem);
    right: 1.1rem;
  }
  
  &:after {
    background: var(--clr-lime);;
  }
}

}
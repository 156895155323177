%tile {
  /* @extend %shadow--1dp;
  @extend %animated-shadow; */
  position: relative;
  padding: var(--spacing-xxl);
  border-radius: 0;
  font-size: calc(0msu * 1rem);
  background-color: var(--clr-white);
  overflow: hidden;

  @media (--large) {
    box-shadow: none;
  }
}

[data-style="2"] {

.tile__heading {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: var(--spacing-xxs);

  .title {
    padding-top: var(--spacing-none);
    padding-bottom: var(--spacing-none);
    margin-right: var(--spacing-xxl);
  }
  .actions {
    align-self: flex-end;

    > *:not(:last-child) {
      margin-right: var(--spacing-s);
    }
  }
  .actions a {
    vertical-align: bottom;
    @extend %ta-r;
    /* compensate for .form-check on .show-archive */
  }
  table {
    margin-bottom: var(--spacing-xxs);
  }
}

.tile__heading--toggle {
  margin-bottom: var(--spacing-none) !important;
}

.tile {
  @extend %tile;
}

.tile__dark {
  background-color: var(--clr-background-dark);
  > svg {
    color: var(--clr-white);
  }
}

.contact__info {
  background-color: var(--clr-white);
  padding: 1em;
  color: var(--clr-text);
  line-height: 1.9;
  .key {
    font-weight: 400;
    padding-right: 1em;
    text-transform: capitalize;
  }
}

}
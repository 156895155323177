/**
 * mixins to help of usage elastic grids
 */
%elastic-grid--horizontal-30,
%elastic-grid--horizontal-20,
%elastic-grid--horizontal-15,
%elastic-grid--horizontal-12,
%elastic-grid--horizontal-10,
%elastic-grid--horizontal-2col,
%elastic-grid--horizontal-3col {
  display: grid;
  grid-column-gap: var(--spacing-xxl);
  grid-row-gap: var(--spacing-xxl);
}

%elastic-grid--horizontal-30 {
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr)) !important;
}

%elastic-grid--horizontal-20 {
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr)) !important;
}

%elastic-grid--horizontal-15 {
  grid-template-columns: repeat(auto-fill, minmax(15rem, 1fr)) !important;
}

%elastic-grid--horizontal-12 {
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr)) !important;
}

%elastic-grid--horizontal-10 {
  grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr)) !important;
}

%elastic-grid--horizontal-2col {
  grid-template-columns: repeat(2, auto) !important;
}

%elastic-grid--horizontal-3col {
  grid-template-columns: repeat(3, auto) !important;
}

%elastic-grid--vertical {
  display: grid !important;
  grid-row-gap: var(--spacing-xxl) !important;
}

%elastic-grid--horizontal-condensed {
  grid-column-gap: calc(2*var(--spacing-l)) !important;
  grid-row-gap: var(--spacing-xs) !important;
}

%elastic-grid--horizontal-gaps--small {
  grid-column-gap: var(--spacing-s) !important;
  grid-row-gap: var(--spacing-s) !important;
}

[data-style="2"] {

/**
 * Part of BO 2017 design
 */


.sidebar {
  min-width: 271px;
  font-size: calc(0msu * 1rem);
}

.sidebar__massactions {
  a {
    color: var(--clr-text);
    text-decoration: none;
    padding-left: 1em;
    display: inline-block;
    height: 40px;
    line-height: 40px;
    font-size: calc(0msu * 1rem);
    font-weight: 500;
  }
  @media(--hover) {
    a:hover {
      color: var(--clr-woodsmoke);
    }
  }
}

.searchbar {
  color: var(--clr-white);
  background-color: var(--clr-dusty-gray);
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1rem;

  @media (--medium) {
    padding-bottom: initial;
  }

  > div {
    border-bottom: 1px solid var(--clr-silver-chalice);
  }
}

.searchbar__input {
  display: flex;
  align-items: center;
  color: var(--clr-white);
  font-size: 110%; /* enlarge the serach icon */
  line-height: 40px;
  height: 40px;

  input {
    height: 29px;
    margin: var(--spacing-xxs) 0;
    flex-grow: 1;
    font-size: calc(0msu * 1rem);
    font-weight: 500;
    border: 0;
    outline:none;
    color: var(--clr-white);
    background-color: var(--clr-dusty-gray);
  }

  input::placeholder {color: var(--clr-silver);}
}

.searchbar_filters {
  display: flex;
  justify-content: flex-start;
  line-height: 40px;
  height: 40px;
}

.searchbar_filter {
  font-size: calc(4msu * 1rem);
  line-height: 40px;
  height: 40px;
  cursor: pointer;
}

.searchbar_filter--active {
  color: var(--clr-sunshade);
}

@media(--hover) {
  .searchbar_filter:hover {
    color: var(--clr-sunshade);
  }
}

.searchbar_actions {
  line-height: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.searchbar_results {
  display: flex;
  max-height: 200px;

  @media (--medium) {
    max-height: inherit;
  }

  li {
    line-height: 31px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.searchbar_results__abcd {
  text-transform: uppercase;
  padding-right: 0.25rem;
  display: none;

  @media (--medium) {
    display: block;
  }

  li {
    font-size: calc(0msu * 1rem);
    line-height: 25px;
    text-align: center;
  }
}

.searchbar_results__items {
  flex-grow: 1;
  overflow: hidden;
  overflow-y: scroll;

  @media (--medium) {
    overflow-y: initial;
  }

}

/* individual results */
.searchbar_result {
  position: relative;
  padding-left: 0.5rem;


  &::before {
    content: "";
    position: absolute;
    left: 0;
    top: 1px;
    bottom: 1px;
    width: 4px;
  }

}

.searchbar_result__person::before { background-color: var(--clr-sunshade) }
.searchbar_result__company::before { background-color: var(--clr-cerulean) }

.searchbar_result--selected {
  background-color: var(--clr-silver-chalice);
}

.searchbar_mylist {
  line-height: 40px;
  height: 40px;
  display: none;

  @media (--medium) {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}

.searchbar_mylist__icons {
  flex-basis: 60px;
  display: flex;
  justify-content: space-between;

  .searchbar_mylist__icon { cursor: pointer;}

  @media(--hover) {
    .searchbar_mylist__icon:hover {
      color: var(--clr-sunshade);
    }
  }
}

.sidebar__queries {
  color: var(--clr-white);
  background-color: var(--clr-dusty-gray);
  padding-left: 1em;
  padding-right: 1em;
  padding-bottom: 1rem;
  display: none;

  @media (--medium) {
    display: block;
  }

}

.sidebar__queries__menu {
  border-bottom: 1px solid var(--clr-silver-chalice);
  display: flex;
  justify-content: space-between;

  a {
    display: inline-block;
    line-height: 40px;
    height: 40px;
    color: var(--clr-white);
    text-decoration: none;
  }

  a.active {
    border-bottom: 2px solid var(--clr-cerulean);
  }

  @media(--hover) {
    a:hover {
      border-bottom: 2px solid var(--clr-cerulean);
    }
  }

}

}

/**
 Utilities mixins - real classes are defined in `base/utilities.css`
 and also extended by any other class
 */

/**
 * Simulates text superscript
 */
%superscript {
  position: relative;
  top: -0.5em;
  font-size: 70%;
}

/**
 * Declarative padding
 */
%padded { padding: calc(1 * 0.25rem); }

/* Measures, used on paragraphs mainly */
%measure-wide { max-width: 54em; }


/*
   SPACING
   Docs: http://tachyons.io/docs/layout/spacing/
   Base:
     p = padding
     m = margin

   Modifiers:
     a = all
     h = horizontal
     v = vertical
     t = top
     r = right
     b = bottom
     l = left
     0 = none
     1 = 1st step in spacing scale
     2 = 2nd step in spacing scale
     3 = 3rd step in spacing scale
     4 = 4th step in spacing scale
     5 = 5th step in spacing scale
     6 = 6th step in spacing scale
     7 = 7th step in spacing scale
*/
%pa0 { padding: var(--spacing-none) !important; }
%pa1 { padding: var(--spacing-xxxs) !important; }
%pa2 { padding: var(--spacing-xxs) !important; }
%pa3 { padding: var(--spacing-xs) !important; } /* this is currently ~ .5rem */
%pa4 { padding: var(--spacing-s) !important; }
%pa5 { padding: var(--spacing-m) !important}
%pa6 { padding: var(--spacing-l) !important; }  /* this is currently 1rem */
%pa7 { padding: var(--spacing-xl) !important; }
%pa8 { padding: var(--spacing-xxl) !important; }
%pa9 { padding: var(--spacing-xxxl) !important; }


%pl0 { padding-left: var(--spacing-none) !important; }
%pl1 { padding-left: var(--spacing-xxxs) !important; }
%pl2 { padding-left: var(--spacing-xxs) !important; }
%pl3 { padding-left: var(--spacing-xs) !important; }
%pl4 { padding-left: var(--spacing-s) !important; }
%pl5 { padding-left: var(--spacing-m) !important; }
%pl6 { padding-left: var(--spacing-l) !important; }
%pl7 { padding-left: var(--spacing-xl) !important; }
%pl8 { padding-left: var(--spacing-xxl) !important; }
%pl9 { padding-left: var(--spacing-xxxl) !important; }


%pr0 { padding-right: var(--spacing-none) !important; }
%pr1 { padding-right: var(--spacing-xxxs) !important; }
%pr2 { padding-right: var(--spacing-xxs) !important; }
%pr3 { padding-right: var(--spacing-xs) !important; }
%pr4 { padding-right: var(--spacing-s) !important; }
%pr5 { padding-right: var(--spacing-m) !important; }
%pr6 { padding-right: var(--spacing-l) !important; }
%pr7 { padding-right: var(--spacing-xl) !important; }
%pr8 { padding-right: var(--spacing-xxl) !important; }
%pr9 { padding-right: var(--spacing-xxxl) !important; }

%pb0 { padding-bottom: var(--spacing-none) !important; }
%pb1 { padding-bottom: var(--spacing-xxxs) !important; }
%pb2 { padding-bottom: var(--spacing-xxs) !important; }
%pb3 { padding-bottom: var(--spacing-xs) !important; }
%pb4 { padding-bottom: var(--spacing-s) !important; }
%pb5 { padding-bottom: var(--spacing-m) !important; }
%pb6 { padding-bottom: var(--spacing-l) !important; }
%pb7 { padding-bottom: var(--spacing-xl) !important; }
%pb8 { padding-bottom: var(--spacing-xxl) !important; }
%pb9 { padding-bottom: var(--spacing-xxxl) !important; }

%pt0 { padding-top: var(--spacing-none) !important; }
%pt1 { padding-top: var(--spacing-xxxs) !important; }
%pt2 { padding-top: var(--spacing-xxs) !important; }
%pt3 { padding-top: var(--spacing-xs) !important; }
%pt4 { padding-top: var(--spacing-s) !important; }
%pt5 { padding-top: var(--spacing-m) !important; }
%pt6 { padding-top: var(--spacing-l) !important; }
%pt7 { padding-top: var(--spacing-xl) !important; }
%pt8 { padding-top: var(--spacing-xxl) !important; }
%pt9 { padding-top: var(--spacing-xxxl) !important; }

%pv0 {
  padding-top: var(--spacing-none) !important;
  padding-bottom: var(--spacing-none) !important;
}
%pv1 {
  padding-top: var(--spacing-xxxs) !important;
  padding-bottom: var(--spacing-xxxs) !important;
}
%pv2 {
  padding-top: var(--spacing-xxs) !important;
  padding-bottom: var(--spacing-xxs) !important;
}
%pv3 {
  padding-top: var(--spacing-xs) !important;
  padding-bottom: var(--spacing-xs) !important;
}
%pv4 {
  padding-top: var(--spacing-s) !important;
  padding-bottom: var(--spacing-s) !important;
}
%pv5 {
  padding-top: var(--spacing-m) !important;
  padding-bottom: var(--spacing-m) !important;
}
%pv6 {
  padding-top: var(--spacing-l) !important;
  padding-bottom: var(--spacing-l) !important;
}
%pv7 {
  padding-top: var(--spacing-xl) !important;
  padding-bottom: var(--spacing-xl) !important;
}
%pv8 {
  padding-top: var(--spacing-xxl) !important;
  padding-bottom: var(--spacing-xxl) !important;
}

%pv9 {
  padding-top: var(--spacing-xxxl) !important;
  padding-bottom: var(--spacing-xxxl) !important;
}

%ph0 {
  padding-left: var(--spacing-none) !important;
  padding-right: var(--spacing-none) !important;
}

%ph1 {
  padding-left: var(--spacing-xxxs) !important;
  padding-right: var(--spacing-xxxs) !important;
}

%ph2 {
  padding-left: var(--spacing-xxs) !important;
  padding-right: var(--spacing-xxs) !important;
}

%ph3 {
  padding-left: var(--spacing-xs) !important;
  padding-right: var(--spacing-xs) !important;
}

%ph4 {
  padding-left: var(--spacing-s) !important;
  padding-right: var(--spacing-s) !important;
}

%ph5 {
  padding-left: var(--spacing-m) !important;
  padding-right: var(--spacing-m) !important;
}

%ph6 {
  padding-left: var(--spacing-l) !important;
  padding-right: var(--spacing-l) !important;
}

%ph7 {
  padding-left: var(--spacing-xl) !important;
  padding-right: var(--spacing-xl) !important;
}

%ph8 {
  padding-left: var(--spacing-xxl) !important;
  padding-right: var(--spacing-xxl) !important;
}

%ph9 {
  padding-left: var(--spacing-xxxl) !important;
  padding-right: var(--spacing-xxxl) !important;
}



%ma0  {  margin: var(--spacing-none) !important; }
%ma1  {  margin: var(--spacing-xxxs) !important; }
%ma2  {  margin: var(--spacing-xxs) !important; }
%ma3  {  margin: var(--spacing-xs) !important; }
%ma4  {  margin: var(--spacing-s) !important; }
%ma5  {  margin: var(--spacing-m) !important; }
%ma6  {  margin: var(--spacing-l) !important; }
%ma7  {  margin: var(--spacing-xl) !important; }
%ma8 {  margin: var(--spacing-xxl) !important; }
%ma9 { margin: var(--spacing-xxxl) !important; }

%ml0  {  margin-left: var(--spacing-none) !important; }
%ml1  {  margin-left: var(--spacing-xxxs) !important; }
%ml2  {  margin-left: var(--spacing-xxs) !important; }
%ml3 {  margin-left: var(--spacing-xs) !important; }
%ml4  {  margin-left: var(--spacing-s) !important; }
%ml5  {  margin-left: var(--spacing-m) !important; }
%ml6  {  margin-left: var(--spacing-l) !important; }
%ml7  {  margin-left: var(--spacing-xl) !important; }
%ml8 {  margin-left: var(--spacing-xxl) !important; }
%ml9 { margin-left: var(--spacing-xxxl) !important; }

%mr0  {  margin-right: var(--spacing-none) !important; }
%mr1  {  margin-right: var(--spacing-xxxs) !important; }
%mr2  {  margin-right: var(--spacing-xxs) !important; }
%mr3 {  margin-right: var(--spacing-xs) !important; }
%mr4  {  margin-right: var(--spacing-s) !important; }
%mr5  {  margin-right: var(--spacing-m) !important; }
%mr6  {  margin-right: var(--spacing-l) !important; }
%mr7  {  margin-right: var(--spacing-xl) !important; }
%mr8 {  margin-right: var(--spacing-xxl) !important; }
%mr9 { margin-right: var(--spacing-xxxl) !important; }
%mr11 { margin-right: 2rem !important; }

%mb0  {  margin-bottom: var(--spacing-none) !important; }
%mb1  {  margin-bottom: var(--spacing-xxxs) !important; }
%mb2  {  margin-bottom: var(--spacing-xxs) !important; }
%mb3 {  margin-bottom: var(--spacing-xs) !important; }
%mb4  {  margin-bottom: var(--spacing-s) !important; }
%mb5  {  margin-bottom: var(--spacing-m) !important; }
%mb6  {  margin-bottom: var(--spacing-l) !important; }
%mb7  {  margin-bottom: var(--spacing-xl) !important; }
%mb8 {  margin-bottom: var(--spacing-xxl) !important; }
%mb9 { margin-bottom: var(--spacing-xxxl) !important; }
%mb10 { margin-bottom: 1.5rem !important; }
%mb11 { margin-bottom: 2rem !important; }

%mt0  {  margin-top: var(--spacing-none) !important; }
%mt1  {  margin-top: var(--spacing-xxxs) !important; }
%mt2  {  margin-top: var(--spacing-xxs) !important; }
%mt3 {  margin-top: var(--spacing-xs) !important; }
%mt4  {  margin-top: var(--spacing-s) !important; }
%mt5  {  margin-top: var(--spacing-m) !important; }
%mt6  {  margin-top: var(--spacing-l) !important; }
%mt7  {  margin-top: var(--spacing-xl) !important; }
%mt8 {  margin-top: var(--spacing-xxl) !important; }
%mt9 { margin-top: var(--spacing-xxxl) !important; }

%mv0   {
  margin-top: var(--spacing-none);
  margin-bottom: var(--spacing-none);
}
%mv1  {
  margin-top: var(--spacing-xxxs);
  margin-bottom: var(--spacing-xxxs);
}
%mv2   {
  margin-top: var(--spacing-xxs);
  margin-bottom: var(--spacing-xxs);
}
%mv3   {
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-xs);
}
%mv4   {
  margin-top: var(--spacing-s);
  margin-bottom: var(--spacing-s);
}
%mv5   {
  margin-top: var(--spacing-m);
  margin-bottom: var(--spacing-m);
}
%mv6   {
  margin-top: var(--spacing-l);
  margin-bottom: var(--spacing-l);
}
%mv7   {
  margin-top: var(--spacing-xl);
  margin-bottom: var(--spacing-xl);
}
%mv8  {
  margin-top: var(--spacing-xxl);
  margin-bottom: var(--spacing-xxl);
}
%mv9  {
  margin-top: var(--spacing-xxxl);
  margin-bottom: var(--spacing-xxxl);
}

%mh0   {
  margin-left: var(--spacing-none);
  margin-right: var(--spacing-none);
}
%mh1   {
  margin-left: var(--spacing-xxxs);
  margin-right: var(--spacing-xxxs);
}
%mh2   {
  margin-left: var(--spacing-xxs);
  margin-right: var(--spacing-xxs);
}
%mh3   {
  margin-left: var(--spacing-xs);
  margin-right: var(--spacing-xs);
}
%mh4   {
  margin-left: var(--spacing-s);
  margin-right: var(--spacing-s);
}
%mh5   {
  margin-left: var(--spacing-m);
  margin-right: var(--spacing-m);
}
%mh6   {
  margin-left: var(--spacing-l);
  margin-right: var(--spacing-l);
}
%mh7   {
  margin-left: var(--spacing-xl);
  margin-right: var(--spacing-xl);
}
%mh8  {
  margin-left: var(--spacing-xxl);
  margin-right: var(--spacing-xxl);
}
%mh9  {
  margin-left: var(--spacing-xxxl);
  margin-right: var(--spacing-xxxl);
}

/*
    BORDERS
    Base:
      b = border
    Modifiers:
      a = all
      t = top
      r = right
      b = bottom
      l = left
      n = none
   Media Query Extensions:
     -ns = not-small
     -m  = medium
     -l  = large
*/

/* First - define mixins, so we can use them anywhere. */
%ba { border-style: solid; border-width: var(--border-width); border-color: var(--clr-border); }
%bt { border-top-style: solid; border-top-width: var(--border-width); border-color: var(--clr-border); }
%br { border-right-style: solid; border-right-width: var(--border-width); border-color: var(--clr-border); }
%bb { border-bottom-style: solid; border-bottom-width: var(--border-width); border-color: var(--clr-border); }
%bl { border-left-style: solid; border-left-width: var(--border-width); border-color: var(--clr-border); }
%bn { border-style: none; border-width: 0; }


 /**
  * font size classes, use them to manually override proportional font size
  */
%fs20 { font-size: calc(20msu * 1rem) !important; }
%fs18 { font-size: calc(18msu * 1rem) !important; }
%fs14 { font-size: calc(14msu * 1rem) !important; }
%fs10 { font-size: calc(10msu * 1rem) !important; }
%fs9 { font-size: calc(9msu * 1rem) !important; }
%fs8 { font-size: calc(8msu * 1rem) !important; }
%fs7 { font-size: calc(7msu * 1rem) !important; }
%fs6 { font-size: calc(6msu * 1rem) !important; }
%fs5 { font-size: calc(5msu * 1rem) !important; }
%fs4 { font-size: calc(4msu * 1rem) !important; }
%fs3 { font-size: calc(3msu * 1rem) !important; }
%fs2 { font-size: calc(2msu * 1rem) !important; }
%fs1 { font-size: calc(1msu * 1rem) !important; }
%fs0 { font-size: calc(0msu * 1rem) !important; }
%fs-1 { font-size: calc(-1msu * 1rem) !important; }
%fs-2 { font-size: calc(-2msu * 1rem) !important; }
%fs-3 { font-size: calc(-3msu * 1rem) !important; }
%fs-4 { font-size: calc(-4msu * 1rem) !important; }
%fs-5 { font-size: calc(-5msu * 1rem) !important; }
%fs-6 { font-size: calc(-6msu * 1rem) !important; }
%fs-7 { font-size: calc(-7msu * 1rem) !important; }
%fs-8 { font-size: calc(-8msu * 1rem) !important; }


/**
 * text alignment
 */
%ta-l   { text-align: left !important; }
%ta-r  { text-align: right !important; }
%ta-c { text-align: center !important; }
%ta-j  { text-align: justify !important; }
%ws-nw   { white-space: nowrap !important; }

/**
 * vertical alignment
 */
%va-m { vertical-align: middle !important; }
%va-t { vertical-align: top !important; }
%va-b { vertical-align: bottom !important; }
%va-tt { vertical-align: text-top !important; }
%va-tb { vertical-align: text-bottom !important; }

[data-style="2"] {

/**
 * This mixin is used to define all background colors
 */

@define-mixin bgcolor $name {
  .bg-$(name) { background-color: var(--clr-$(name)) !important; }
}

/**
 * This mixin is used to define all text colors
 */

 @define-mixin color $name {
  .clr-$(name) { color: var(--clr-$(name)) !important; }
}

}

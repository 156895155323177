.drooltip { 
    position: absolute; 
    margin: -52px 0 0 65px;
}

.tooltip-content {
    background-color: var(--clr-gray);
    padding: var(--spacing-xs);
    border-radius: 3px;
}

.hideTooltip {
    display: none !important;
}
[data-style="2"] {

/**
 * Utility classes for simple animations.
 */

.animated {
  animation-duration: .35s;
  animation-fill-mode: forwards;
  animation-timing-function: ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

.animated-delay-1 {
  animation-delay: 0.5s;
}


/** mixing for animated shadow */
%animated-shadow {
  transition: box-shadow 0.25s ease-in-out;
}


/**
 * js controled sliding animations
 */
.slide-down {
    max-height: 0;            
    overflow-y: hidden;
    transition: max-height 0.35s ease-in-out;
}

.slide-down.open {            
    max-height: 1000em;
}

}
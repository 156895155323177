[data-style="2"] {

  .note {
    font-weight: 400;
    margin-bottom: var(--spacing-xs);
    position: relative;
    border-left: 5px solid var(--clr-border);
    border-left-color: var(--clr-default-dark);
    background-color: var(--clr-default-light);
    border-radius: var(--base-radius);
    padding: var(--spacing-s);

    &-spacy {
      padding: var(--spacing-xxl);
      margin-bottom: var(--spacing-s);
      line-height: 26px;
    }

    ul {
      list-style: initial;
      list-style-position: inside;
    }

    &.success,
    &--success {
      border-left-color: var(--clr-success-dark);
      background-color: var(--clr-success-light);
    }

    &.error,
    &--error {
      border-left-color: var(--clr-error-dark);
      background-color: var(--clr-error-light);
    }

    &.warning,
    &--warning {
      border-left-color: var(--clr-warning-dark);
      background-color: var(--clr-warning-light);
    }

    &.info,
    &--info {
      border-left-color: var(--clr-info-dark);
      background-color: var(--clr-info-light);
    }
  }
}

.tags-editor.card {
  padding: var(--spacing-s) var(--spacing-xxs) var(--spacing-s) var(--spacing-s);
  overflow: initial !important;

  @media(--hover) {
    &:hover {
      box-shadow: none !important;
    }
  }

  .card__heading {
    margin-bottom: var(--spacing-m);
  }

  .form-group {
    margin: var(--spacing-none) var(--spacing-xs) var(--spacing-xs) var(--spacing-none);
  }

  &.tags-editor--deletable .card__content {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    > span, > select {
      margin-right: var(--spacing-xs);
    }

    > select {
      width: auto;
    }
  }

  &.tags-editor--draggable .card__content {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: flex-start;

    > span {
      margin-top: var(--spacing-xs);
      margin-right: var(--spacing-xs);
    }
  }
}

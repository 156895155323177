.progress {
  display: flex;
  flex-direction: column;
  
  .progress__bar {
    background-color: var(--clr-gallery);
    height: .75rem;
    width: 100%;
    border-radius: 3px;
  }
  .progress__bar__content {
      height: 100%;
      background-color: var(--clr-cerulean);
      border-radius: 3px;
  }
}




.snackbar-container {
  background-color: var(--clr-scorpion);
  color: var(--clr-white);
  z-index: var(--z-index-highest);
  font-family: var(--family-sans) !important;
  /* sliding animation */
  max-height: 0;  /* hide by default */
  transition: max-height 0.45s ease-in-out;
  font-size: 110%;
  overflow: hidden;
  width: 80%;
  position: fixed;
  bottom: 0;
  display: inline-flex;
  box-shadow: 0 0 2px 2px rgba(0,0,0,.12);
  /* positioning - can be extracted into own classes */
  margin: 0 var(--spacing-l);
  padding: 0 var(--spacing-l);
  top: auto !important;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);

  span {
    padding: var(--spacing-l) 0;
  }

  /* styling of action buttons */
  .action {
    background: inherit;
    display: inline-block;
    border: none;
    font-size: inherit;
    text-transform: uppercase;
    margin: 0 0 0 var(--spacing-l);
    padding: 0;
    min-width: min-content;
    cursor: pointer;
    outline: none;
    font-weight: 700;
  }

  /* level styling e.g "info", "success" */
  border-left: solid .6rem;

  &.info { 
    border-left-color: var(--clr-info-dark);
    .action {
      color: var(--clr-info-light);
    }
  }

  &.success { 
    border-left-color: var(--clr-success-dark); 
    .action { 
      color: var(--clr-success-light); 
    }
  }

  &.warn { 
    border-left-color: var(--clr-warning-dark);
    .action { 
      color: var(--clr-warning-light);
    }
  }
  
  &.error { 
    border-left-color: var(--clr-error-dark);
    .action {
      color: var(--clr-error-light);
    }
  }
}

@media (--medium) {
  .snackbar-container {
    width: auto;
  }
}
@import 'flatpickr';

div.datepicker {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  color: var(--clr-text);

  .datepicker--date, .datepicker--time {
    position: relative;

    i {
      cursor: pointer;
    }
  }

  .datepicker--readonly {
    input, i {
      cursor: default !important;
    }

    i {
      color: var(--clr-alto);
    }
  }

  .datepicker--date {
    width: 112px;
  }

  .datepicker--date:not(:last-child) {
    margin-right: var(--spacing-s);
  }

  .datepicker--time:not(.meridiam-time) {
    width: 80px;
  }

  .datepicker--time.meridiam-time {
    width: 100px;
  }
  
  i {
    position: absolute;
    top: 12px;
    right: 10px;
  }
}

span.flatpickr-day.today.selected {
  background-color: var(--clr-cerulean);
}

.flatpickr-day.today:hover, .flatpickr-day.today:focus {
  background-color: var(--clr-gray);
}

.flatpickr-current-month {
  select {
    display: inline-block;
    font-size: inherit !important;
  }
  input {
    font-size: inherit !important;
  }
}

.profile-categories {
  padding: 0 var(--spacing-xxl);

  .profile-categories__header {
    font-weight: 700;
    color: var(--clr-gray);
  }

  span:not(:last-child) {
    padding-right: var(--spacing-m);
  }

  span:not(:last-child):after {
    font-family: var(--family-awesome);
    text-decoration: unset !important;
    content: "\f105";
    padding-left: var(--spacing-m);
    color: var(--clr-silver);
    font-size: 14px;
    font-weight: 700;
  }

  .profile-categories__row {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: var(--spacing-xxs);
    padding-top: var(--spacing-xxs);

    .searchable {
      max-width: 100%;
     
      > span {
        white-space: nowrap; 
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    > div {
      white-space: nowrap;
      display: flex;
      align-items: center;
      max-width: 100%;
      margin-top: var(--spacing-xxs);
      margin-bottom: var(--spacing-xxs);
    }

    div:not(:last-child) {
      padding-right: var(--spacing-m);
    }
  
    div:not(:last-child):after {
      font-family: var(--family-awesome);
      text-decoration: unset !important;
      content: "\f105";
      padding-left: var(--spacing-m);
      color: var(--clr-silver);
      font-size: 14px;
      font-weight: 700;
    }
  }

  .profile-categories__row:not(:last-child) {
    border-bottom: 1px solid var(--clr-gallery);
  }
}
/**
 * Utility classes
 *
 *
 */


/**
* Main content containers
* 1. Make the container full-width with a maximum width
* 2. Center it in the viewport
* 3. Leave some space on the edges, especially valuable on small screens
*/

[data-style="2"] {

.container {
  width: 100%; /* 1 */
  margin-left: auto; /* 2 */
  margin-right: auto; /* 2 */
  padding-left: var(--spacing-xl); /* 3 */
  padding-right: var(--spacing-xl); /* 3 */

  @media (--medium) {
    max-width: var(--max-width); /* 1 */
  }
}

}

.centered {
  margin-left: auto;
  margin-right: auto;
}


/**
 * Classic clearfix tric
 */
.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.superscript { @extend %superscript };

.muted { color: var(--clr-gray) !important; }

.padded { @extend %padded };

/* All padding */
.pa0 { @extend %pa0; }
.pa1 { @extend %pa1; }
.pa2 { @extend %pa2; }
.pa3 { @extend %pa3; }
.pa4 { @extend %pa4; }
.pa5 { @extend %pa5; }
.pa6 { @extend %pa6; }
.pa7 { @extend %pa7; }
.pa8 { @extend %pa8; }
.pa9 { @extend %pa9; }

/* Left padding */
.pl0 { @extend %pl0; }
.pl1 { @extend %pl1; }
.pl2 { @extend %pl2; }
.pl3 { @extend %pl3; }
.pl4 { @extend %pl4; }
.pl5 { @extend %pl5; }
.pl6 { @extend %pl6; }
.pl7 { @extend %pl7; }
.pl8 { @extend %pl8; }
.pl9 { @extend %pl9; }

/* Right padding */
.pr0 { @extend %pr0; }
.pr1 { @extend %pr1; }
.pr2 { @extend %pr2; }
.pr3 { @extend %pr3; }
.pr4 { @extend %pr4; }
.pr5 { @extend %pr5; }
.pr6 { @extend %pr6; }
.pr7 { @extend %pr7; }
.pr8 { @extend %pr8; }
.pr9 { @extend %pr9; }

/* Bottom padding */
.pb0 { @extend %pb0; }
.pb1 { @extend %pb1; }
.pb2 { @extend %pb2; }
.pb3 { @extend %pb3; }
.pb4 { @extend %pb4; }
.pb5 { @extend %pb5; }
.pb6 { @extend %pb6; }
.pb7 { @extend %pb7; }
.pb8 { @extend %pb8; }
.pb9 { @extend %pb9; }

/* Top padding */
.pt0 { @extend %pt0; }
.pt1 { @extend %pt1; }
.pt2 { @extend %pt2; }
.pt3 { @extend %pt3; }
.pt4 { @extend %pt4; }
.pt5 { @extend %pt5; }
.pt6 { @extend %pt6; }
.pt7 { @extend %pt7; }
.pt8 { @extend %pt7; }
.pt9 { @extend %pt7; }

/* Vertival padding */
.pv0 { @extend %pv0; }
.pv1 { @extend %pv1; }
.pv2 { @extend %pv2; }
.pv3 { @extend %pv3; }
.pv4 { @extend %pv4; }
.pv5 { @extend %pv5; }
.pv6 { @extend %pv6; }
.pv7 { @extend %pv7; }
.pv8 { @extend %pv8; }
.pv9 { @extend %pv9; }

/* Horizontal padding */
.ph0 { @extend %ph0; }
.ph1 { @extend %ph1; }
.ph2 { @extend %ph2; }
.ph3 { @extend %ph3; }
.ph4 { @extend %ph4; }
.ph5 { @extend %ph5; }
.ph6 { @extend %ph6; }
.ph7 { @extend %ph7; }
.ph8 { @extend %ph8; }
.ph9 { @extend %ph9; }

.ma0 { @extend %ma0; }
.ma1 { @extend %ma1; }
.ma2 { @extend %ma2; }
.ma3 { @extend %ma3; }
.ma4 { @extend %ma4; }
.ma5 { @extend %ma5; }
.ma6 { @extend %ma6; }
.ma7 { @extend %ma7; }
.ma8 { @extend %ma8; }
.ma9 { @extend %ma9; }

.ml0 { @extend %ml0; }
.ml1 { @extend %ml1; }
.ml2 { @extend %ml2; }
.ml3 { @extend %ml3; }
.ml4 { @extend %ml4; }
.ml5 { @extend %ml5; }
.ml6 { @extend %ml6; }
.ml7 { @extend %ml7; }
.ml8 { @extend %ml8; }
.ml9 { @extend %ml9; }

.mr0 { @extend %mr0; }
.mr1 { @extend %mr1; }
.mr2 { @extend %mr2; }
.mr3 { @extend %mr3; }
.mr4 { @extend %mr4; }
.mr5 { @extend %mr5; }
.mr6 { @extend %mr6; }
.mr7 { @extend %mr7; }
.mr8 { @extend %mr8; }
.mr9 { @extend %mr9; }
.mr11 { @extend %mr11; }

.mt0 { @extend %mt0; }
.mt1 { @extend %mt1; }
.mt2 { @extend %mt2; }
.mt3 { @extend %mt3; }
.mt4 { @extend %mt4; }
.mt5 { @extend %mt5; }
.mt6 { @extend %mt6; }
.mt7 { @extend %mt7; }
.mt8 { @extend %mt8; }
.mt9 { @extend %mt9; }

.mb0 { @extend %mb0; }
.mb1 { @extend %mb1; }
.mb2 { @extend %mb2; }
.mb3 { @extend %mb3; }
.mb4 { @extend %mb4; }
.mb5 { @extend %mb5; }
.mb6 { @extend %mb6; }
.mb7 { @extend %mb7; }
.mb8 { @extend %mb8; }
.mb9 { @extend %mb9; }
.mb10 { @extend %mb10; }
.mb11 { @extend %mb11; }
.mb4-mobile { @media screen and (--up-to-medium) { margin-bottom: 8px !important; } }

.mv0 { @extend %mv0; }
.mv1 { @extend %mv1; }
.mv2 { @extend %mv2; }
.mv3 { @extend %mv3; }
.mv4 { @extend %mv4; }
.mv5 { @extend %mv5; }
.mv6 { @extend %mv6; }
.mv7 { @extend %mv7; }
.mv8 { @extend %mv8; }
.mv9 { @extend %mv9; }

.mh0 { @extend %mh0; }
.mh1 { @extend %mh1; }
.mh2 { @extend %mh2; }
.mh3 { @extend %mh3; }
.mh4 { @extend %mh4; }
.mh5 { @extend %mh5; }
.mh6 { @extend %mh6; }
.mh7 { @extend %mh7; }
.mh8 { @extend %mh8; }
.mh9 { @extend %mh9; }

.ml-auto { margin-left: auto; }
.mr-auto { margin-right: auto; }
.mt-auto { margin-top: auto; }
.mb-auto { margin-bottom: auto; }

 /**
  * font size classes, use them to manually override proportional font size
  */
.fs20 { @extend %fs20; }
.fs14 { @extend %fs14; }
.fs10 { @extend %fs10; }
.fs9 { @extend %fs9; }
.fs8 { @extend %fs8; }
.fs7 { @extend %fs7; }
.fs6 { @extend %fs6; }
.fs5 { @extend %fs5; }
.fs4 { @extend %fs4; }
.fs3 { @extend %fs3; }
.fs2 { @extend %fs2; }
.fs1 { @extend %fs1; }
.fs0 { @extend %fs0; }
.fs-1 { @extend %fs-1; }
.fs-2 { @extend %fs-2; }
.fs-3 { @extend %fs-3; }
.fs-4 { @extend %fs-4; }
.fs-5 { @extend %fs-5; }
.fs-6 { @extend %fs-6; }
.fs-7 { @extend %fs-7; }
.fs-8 { @extend %fs-8; }


.fs80 { font-size: 80% !important }
.fs90 { font-size: 90% !important }
.fs100 { font-size: 100% !important }
.fs110 { font-size: 110% !important }
.fs120 { font-size: 120% !important }

/**
 * text alignment
 */
.ta-l   { @extend %ta-l; }
.ta-r  { @extend %ta-r; }
.ta-c { @extend %ta-c; }
.ta-j  { @extend %ta-j; }
.ws-nw   { @extend %ws-nw; }

/**
 * vertical alignment
 */
.va-m   { @extend %va-m; }
.va-t  { @extend %va-t; }
.va-b { @extend %va-b; }
.va-tt  { @extend %va-tt; }
.va-tb   { @extend %va-tb; }

/* Text decoration */

.tdn {
  text-decoration: none !important;
}

.tdn--hover:hover {
  text-decoration: none !important;
}

.tdu {
  text-decoration: underline !important;
}

/**
 * Cursor
 */
 .cur-po {
   cursor: pointer !important;
 }

/**
 * Transformation
 */

.ttc { text-transform: capitalize; }
.ttl { text-transform: lowercase; }
.ttu { text-transform: uppercase; }
.ttn { text-transform: none; }

/** Weight and italics */
.normal { font-weight: normal; }
.b      { font-weight: bold; }
.fw1    { font-weight: 100; }
.fw2    { font-weight: 200; }
.fw3    { font-weight: 300; }
.fw4    { font-weight: 400; }
.fw5    { font-weight: 500; }
.fw6    { font-weight: 600; }
.fw7    { font-weight: 700; }
.fw8    { font-weight: 800; }
.fw9    { font-weight: 900; }



/** Contextual colors  */
.tw { color: var(--clr-white) !important;}
.tb { color: var(--clr-black) !important;}
.ts { color: var(--clr-silver) !important;}
.tg { color: var(--clr-gray) !important;}
.td { color: var(--clr-text) !important;}

/*
line behind text
 Source: https://css-tricks.com/forums/topic/css-trick-for-a-horizontal-type-line-behind-text/#post-132492
 */
.line-behind {
  position: relative;
  overflow: hidden;
  text-align: center;

  span {
    display: inline-block;
    vertical-align: baseline;
    zoom: 1;
    /**display: inline;
    *vertical-align: auto;*/
    position: relative;
    padding: 0 20px;

    &:before, &:after {
        content: '';
        display: block;
        width: 1000px;
        position: absolute;
        top: 0.73em;
        border-top: 1px solid var(--clr-border);
    }

    &:before { right: 100%; }
    &:after { left: 100%; }
  }
}

.measure-wide { @extend %measure-wide }

/** shadows */
.shadow--1dp { @extend %shadow--1dp; }


/* Borders */
.ba { @extend %ba; }
.bt { @extend %bt; }
.br { @extend %br; }
.bb { @extend %bb; }
.bl { @extend %bl; }
.bn { @extend %bn; }

.b--dotted { border-style: dotted !important; }
.b--dashed { border-style: dashed !important; }
.b--solid {  border-style: solid !important; }
.b--none {   border-style: none !important; }

.bw0 { border-width: 0 !important; }
.bw1 { border-width: 1px !important; }
.bw2 { border-width: 2px !important; }
.bw3 { border-width: 3px !important; }
.bw4 { border-width: 4px !important; }
.bw5 { border-width: 5px !important; }

.brad0 { border-radius: 0 !important; }
.brad1 { border-radius: .125rem !important; }
.brad2 { border-radius: .25rem !important; }
.brad3 { border-radius: .5rem !important; }
.brad4 { border-radius: 1rem !important; }
.brad5 { border-radius: 2rem !important; }

/* Resets */
.bt-0 { border-top-width: 0 !important; }
.br-0 { border-right-width: 0 !important; }
.bb-0 { border-bottom-width: 0 !important; }
.bl-0 { border-left-width: 0 !important; }

/* Border colors are placed in colors.css */

/* background size */
.cover { background-size: cover !important; }
.contain { background-size: contain !important; }


/* display utilites */
.dn { display: none !important; }
.db { display: block !important; }
.dib { display: inline-block !important; }


.dn--hover {
  @media (hover: hover) {
    display: none !important;
  }
}

* > .show-on-parent-hover {
  visibility: hidden;
}

*:hover > .show-on-parent-hover,
.is-open.show-on-parent-hover {
  visibility: visible;
}

.dn--medium {
  @media (--medium) {
    display: none !important;
  }
}

.dn--large {
  @media (--large) {
    display: none !important;
  }
}

.dn--wide {
  @media (--wide) {
    display: none !important;
  }
}

.dn--up-to-medium {
  @media (--up-to-medium) {
    display: none !important;
  }
}

.dn--up-to-large {
  @media (--up-to-large) {
    display: none !important;
  }
}

.dn--up-to-wide {
  @media (--up-to-wide) {
    display: none !important;
  }
}

/*
FLEXBOX
  Media Query Extensions:
   -ns = not-small
   -m  = medium
   -l  = large
*/
.df { display: flex !important; }
.inline-flex { display: inline-flex !important; }

/* 1. Fix for Chrome 44 bug.
 * https://code.google.com/p/chromium/issues/detail?id=506893 */
.flex-auto {
  flex: 1 1 auto;
  min-width: 0; /* 1 */
  min-height: 0; /* 1 */
}

.flex-none { flex: none !important; }

.flex-column  { flex-direction: column !important; }
.flex-row     { flex-direction: row !important; }
.flex-wrap    { flex-wrap: wrap !important; }
.flex-nowrap    { flex-wrap: nowrap !important; }
.flex-wrap-reverse    { flex-wrap: wrap-reverse !important; }
.flex-column-reverse  { flex-direction: column-reverse !important; }
.flex-row-reverse     { flex-direction: row-reverse !important; }

.items-start    { align-items: flex-start !important; }
.items-end      { align-items: flex-end !important; }
.items-center   { align-items: center !important; }
.items-baseline { align-items: baseline !important; }
.items-stretch  { align-items: stretch !important; }

.self-start    { align-self: start !important; }
.self-end      { align-self: end !important; }
.self-center   { align-self: center !important; }
.self-baseline { align-self: baseline !important; }
.self-stretch  { align-self: stretch !important; }
.self-flex-start    { align-self: flex-start !important; }
.self-flex-end      { align-self: flex-end !important; }

.justify-start   { justify-content: flex-start !important; }
.justify-end     { justify-content: flex-end !important; }
.justify-center  { justify-content: center !important; }
.justify-between { justify-content: space-between !important; }
.justify-around  { justify-content: space-around !important; }

.content-start   { align-content: flex-start !important; }
.content-end     { align-content: flex-end !important; }
.content-center  { align-content: center !important; }
.content-between { align-content: space-between !important; }
.content-around  { align-content: space-around !important; }
.content-stretch { align-content: stretch !important; }

.order-0 { order: 0 !important; }
.order-1 { order: 1 !important; }
.order-2 { order: 2 !important; }
.order-3 { order: 3 !important; }
.order-4 { order: 4 !important; }
.order-5 { order: 5 !important; }
.order-6 { order: 6 !important; }
.order-7 { order: 7 !important; }
.order-8 { order: 8 !important; }
.order-last { order: 99999 !important;}

.flex-grow-0 { flex-grow: 0 !important; }
.flex-grow-1 { flex-grow: 1 !important; }

.flex-shrink-0 { flex-shrink: 0 !important; }
.flex-shrink-1 { flex-shrink: 1 !important; }

.flex-keep-between > *:last-child {
  margin-left: auto !important;
}

.flex-gap--right, .flex-gap--left, .flex-gap--vertical {
  display: flex;
}

.flex-gap--right, .flex-gap--left {
  align-items: flex-start;
}

.flex-gap--right {
  justify-content: flex-end;
  flex-wrap: wrap;

  > *:not(:first-child) {
    margin-left: var(--spacing-s);
  }
}

.flex-gap--left {
  justify-content: flex-start;
  flex-wrap: wrap;

  > *:not(:last-child) {
    margin-right: var(--spacing-s);
  }
}

.flex-gap--vertical {
  flex-direction: column;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: var(--spacing-s);
  }
}

.flex-gap--mb {
  margin-bottom: calc(-1 * var(--spacing-s));

  > * {
    margin-bottom: var(--spacing-s);
  }
}

.gap--vertical-items {
  > *:not(:last-child) {
    margin-bottom: var(--spacing-s);
  }
}

/** Grid utilities */
.elastic-grid--horizontal-30 {
  @extend %elastic-grid--horizontal-30;

  > * { align-self: start; }
}

.elastic-grid--horizontal-20 {
  @extend %elastic-grid--horizontal-20;

  > * { align-self: start; }
}

.elastic-grid--horizontal-15 {
  @extend %elastic-grid--horizontal-15;

  > * { align-self: start; }
}

.elastic-grid--horizontal-12 {
  @extend %elastic-grid--horizontal-12;

  > * { align-self: start; }
}

.elastic-grid--horizontal-10 {
  @extend %elastic-grid--horizontal-10;

  > * { align-self: start; }
}

.elastic-grid--vertical {
  @extend %elastic-grid--vertical;

  > * { align-self: start; }
}

.elastic-grid--horizontal-2col {
  @extend %elastic-grid--horizontal-2col;
}

.elastic-grid--horizontal-3col {
  @extend %elastic-grid--horizontal-3col;
}

.elastic-grid--horizontal-condensed {
  @extend %elastic-grid--horizontal-condensed;
}

.elastic-grid--horizontal-gaps--small {
  @extend %elastic-grid--horizontal-gaps--small;
}

/** grid row span */
.grs2 { grid-row: span 2 !important; }
.grs3 { grid-row: span 3 !important; }
.grs4 { grid-row: span 4 !important; }
.gcs2 { grid-column: span 2 !important; }
.gcs3 { grid-column: span 3 !important; }
.gcs4 { grid-column: span 4 !important; }

.gcst1 { grid-column-start: 1 !important; }
.gc-all { grid-column: 1 / -1; }

.bg-default-dark { color: var(--clr-white) !important; }
.bg-custom-dark { color: var(--clr-white) !important; }
.bg-success-dark { color: var(--clr-white) !important; }
.bg-info-dark { color: var(--clr-white) !important; }
.bg-warning-dark { color: var(--clr-white) !important; }

.minw5ch { min-width: 5ch !important; }
.minw10ch { min-width: 10ch !important; }
.minw15ch { min-width: 15ch !important; }
.minw20ch { min-width: 20ch !important; }
.minw30ch { min-width: 30ch !important; }

.maxw100ch { max-width: 100ch !important; }
.maxw200ch { max-width: 200ch !important; }

/* max-width in rems */
.maxw3rem[data-style="2"] { max-width: 3rem !important;} /* 48px*/
.maxw5rem[data-style="2"] { max-width: 5rem !important;} /* 80px*/
.maxw20rem[data-style="2"] { max-width: 20rem !important;} /* 320px*/
.maxw30rem[data-style="2"] { max-width: 30rem !important;} /* 480px*/
.maxw40rem[data-style="2"] { max-width: 40rem !important;} /* 640px*/
.maxw50rem[data-style="2"] { max-width: 50rem !important;} /* 800px*/
.maxw60rem[data-style="2"] { max-width: 60rem !important;} /* 800px*/

/* max-width in rems */
.maxw3rem { max-width: 3rem !important;} /* 48px*/
.maxw5rem { max-width: 5rem !important;} /* 80px*/
.maxw20rem { max-width: 20rem !important;} /* 320px*/
.maxw30rem { max-width: 30rem !important;} /* 480px*/
.maxw40rem { max-width: 40rem !important;} /* 640px*/
.maxw50rem { max-width: 50rem !important;} /* 800px*/
.maxw60rem { max-width: 60rem !important;} /* 800px*/
.maxw50perc { max-width: 50%  !important;}

.maxw960 { max-width: 960px;}

.maxh40vh { max-height: 40vh !important;}
.maxh50vh { max-height: 50vh !important;}
.maxh60vh { max-height: 60vh !important;}
.maxh70vh { max-height: 70vh !important;}
.maxh80vh { max-height: 60vh !important;}

.h10rem { height: 10rem !important;}

/* changing color to blue on hover, example: categorization */
@media(--hover) {
  .hover-blue:hover {
    color: var(--clr-action) !important;
  }
}

/* widths */
.w100perc { width: 100%; }
.h100perc { height: 100%; }
.w-fc { width: fit-content !important;}

/* heights */
.h-fc { height: fit-content !important; }

/* line-height utilities */
.lh-small {
  line-height: 1;
}

.lh-medium {
  line-height: 1.4;
}

.lh-large {
  line-height: 1.7;
}

.ov-y-hidden {
  overflow-y: hidden !important;
}

.ov-x-hidden {
  overflow-x: hidden !important;
}

.ov-y-auto {
  overflow-y: auto !important;
}

.ov-x-auto {
  overflow-x: auto !important;
}

.text-ov-disable > span {
  -o-text-overflow: initial !important;   /* Opera */
  text-overflow:    initial !important;   /* IE, Safari (WebKit) */
  overflow: initial !important;              /* don't show excess chars */
  max-width: 100% !important;             /* fixed width */
}

.text-ov-enable, .text-ov-enable > span, .text-ov-enable > a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-ov-enable:not(.searchable) > span, .text-ov-enable:not(.non-clickable) > span, .text-ov-enable:not(.profile-links) > a {
  display: block !important; /* it won't work on links without this */
}

.word-break {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  white-space: normal;
}

.text-ellipsis {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

[data-style="2"] {

  /* modules icons */
  .icon-people:before, .icon-companies:before, .icon-films:before, .icon-film_packages:before, .icon-events:before, .icon-missions:before {
    font-family: var(--family-awesome);
    font-weight: 700;
    font-style: normal;
    margin-left: 0;
    margin-right: 0;
    width: auto;
  }

  .icon-people:before {
    content: "\f007";
  }

  .icon-companies:before {
    content: "\f0b1";
  }

  .icon-films:before {
    content: "\f03d";
  }

  .icon-film_packages:before {
    content: "\f1c8";
  }

  .icon-events:before {
    content: "\f79f";
  }

  .icon-missions:before {
    content: "\f46c";
  }
}

.notransition {
  transition: none !important;
}

.res-vertical {
	resize: vertical;
}

.res-horizontal {
  resize: horizontal;
  overflow-x: auto;
}

.curp {
  cursor: pointer !important;
}

.cur-de {
  cursor: default !important;
}

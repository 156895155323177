[data-style="2"] {

.swatches {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;

  .color-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

@mixin swatch-color shadow, rgba(0,0,0, .125), var(--clr-text);
@mixin swatch-color primary, var(--clr-primary), var(--clr-white);
@mixin swatch-color secondary, var(--clr-secondary), var(--clr-white);
@mixin swatch-color accent, var(--clr-err), var(--clr-white);
@mixin swatch-color background, var(--clr-white), var(--clr-text);
@mixin swatch-color background-shade, var(--clr-err), var(--clr-text);
@mixin swatch-color background-invert,border var(--clr-err), var(--clr-white);
@mixin swatch-color text, var(--clr-err), var(--clr-white);
@mixin swatch-color text-weak, var(--clr-err), var(--clr-white);
@mixin swatch-color text-strong, var(--clr-text), var(--clr-white);
@mixin swatch-color text-heading, var(--clr-err), var(--clr-white);
@mixin swatch-color text-invert, rgba(var(--clr-background), .75), var(--clr-white);
@mixin swatch-color text-strong-invert, var(--clr-background), var(--clr-white);
@mixin swatch-color text-link, var(--clr-primary), var(--clr-white);
@mixin swatch-color text-link-visited, var(--clr-err), var(--clr-white);
@mixin swatch-color text-link-hover, var(--clr-text), var(--clr-white);
@mixin swatch-color border, var(--clr-err), var(--clr-white);


/* eventival real colors */
@mixin swatch black, var(--clr-black), var(--clr-white);
@mixin swatch scorpion, var(--clr-scorpion), var(--clr-white);
@mixin swatch dove-gray, var(--clr-dove-gray), var(--clr-white);
@mixin swatch gray, var(--clr-gray), var(--clr-white);
@mixin swatch alto, var(--clr-alto), var(--clr-text);
@mixin swatch silver, var(--clr-silver), var(--clr-white);
@mixin swatch gallery, var(--clr-gallery), var(--clr-text);
@mixin swatch black-10, var(--clr-black-10), var(--clr-text);
@mixin swatch black-5, var(--clr-black-5), var(--clr-text);
@mixin swatch athens-gray, var(--clr-athens-gray), var(--clr-text);
@mixin swatch tahuna-sands, var(--clr-tahuna-sands), var(--clr-text);
@mixin swatch magic-mint, var(--clr-magic-mint), var(--clr-text);
@mixin swatch lime, var(--clr-lime), var(--clr-white);
@mixin swatch conifer, var(--clr-conifer), var(--clr-white);
@mixin swatch christi, var(--clr-christi), var(--clr-white);
@mixin swatch french-pass, var(--clr-french-pass), var(--clr-text);
@mixin swatch cupid, var(--clr-cupid), var(--clr-text);
@mixin swatch thunderbird, var(--clr-thunderbird), var(--clr-white);
@mixin swatch alizarin-crimson, var(--clr-alizarin-crimson), var(--clr-white);
@mixin swatch mandy, var(--clr-mandy), var(--clr-white);
@mixin swatch picton-blue, var(--clr-picton-blue), var(--clr-white);
@mixin swatch zircon, var(--clr-zircon), var(--clr-white);
@mixin swatch cerulean, var(--clr-cerulean), var(--clr-white);
@mixin swatch cerulean-10, var(--clr-cerulean-10), var(--clr-text);
@mixin swatch fire-bush, var(--clr-fire-bush), var(--clr-white);
@mixin swatch sunshade, var(--clr-sunshade), var(--clr-white);
@mixin swatch creme-brulee, var(--clr-creme-brulee), var(--clr-text);
@mixin swatch varden, var(--clr-varden), var(--clr-text);
@mixin swatch sunshade-10, var(--clr-sunshade-10), var(--clr-text);
@mixin swatch orange-peel, var(--clr-orange-peel), var(--clr-text);
@mixin swatch white, var(--clr-white), var(--clr-text);
@mixin swatch onahau, var(--clr-onahau), var(--clr-text);
@mixin swatch malachite, var(--clr-malachite), var(--clr-text);
@mixin swatch hot-cinnamon, var(--clr-hot-cinnamon), var(--clr-white);

@mixin swatch gray, var(--clr-gray), var(--clr-white);
@mixin swatch silver-chalice, var(--clr-silver-chalice), var(--clr-white);
@mixin swatch dusty-gray, var(--clr-dusty-gray), var(--clr-white);
@mixin swatch dove-gray-darker, var(--clr-dove-gray-darker), var(--clr-white);
@mixin swatch buttercup, var(--clr-buttercup), var(--clr-white);
@mixin swatch buttermilk, var(--clr-buttermilk), var(--clr-text);
@mixin swatch texas-rose, var(--clr-texas-rose), var(--clr-text);
@mixin swatch golden-grass, var(--clr-golden-grass), var(--clr-white);
@mixin swatch woodsmoke, var(--clr-woodsmoke), var(--clr-white);
@mixin swatch lochmara, var(--clr-lochmara), var(--clr-white);
@mixin swatch steel-blue, var(--clr-steel-blue), var(--clr-white);
@mixin swatch salem, var(--clr-salem), var(--clr-white);
@mixin swatch lily-white, var(--clr-lily-white), var(--clr-text);

}
[data-style="2"] {

  .tooltip-content {
    display: flex;
    justify-content: flex-start;
    min-width: 200px;
    max-width: 500px;
    line-height: 1.4;
    /* align-items: center; */

    .avatar {
      width: 50px;
      height: 50px;
      margin-right: var(--spacing-s);

      div, i {
        @extend %fs9;
      }
    }

    > div.key--wrapper {
      display: flex;
      flex-direction: column;

      span {
        @extend %fs-1;
      }
    }
  }
}

[data-style="2"] {

@define-mixin swatch-color $name, $backgroundColor, $color {
  .swatch-color-$(name) {
    background-color: $(backgroundColor);
    color: $(color);
  }
}


@define-mixin swatch $name, $backgroundColor, $color {
  .swatch-$(name), .swatch {
    background-color: $(backgroundColor);
    color: $(color);

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    height: 140px;
    width: 140px;
    border-radius: 50%;
    border: 3px solid var(--clr-white);
    @extend %shadow--1dp;
    /*padding-top: 37px;*/
    /*margin-bottom: 15px;*/
    text-align: center;
    margin: var(--spacing-xl);

    &:hover {  @extend %shadow--1dp; }

    code {
      background-color: unset;
      color: $(color);
    }
  }
}

}
/* responsive table component */

table.table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  border-bottom: 1px solid var(--clr-table-border);
  background-color: var(--clr-table-bg);

  /* exeptional desktop first attitude, because of js measurement for .js-table */
  > * > tr {
    padding: var(--spacing-none);
    font-weight: 400;

    > td,
    > th {
      text-align: left;
      padding: var(--spacing-xxs) var(--spacing-none) var(--spacing-xxs) var(--spacing-xs) !important;
      border-top: 1px solid var(--clr-table-border);

      &:last-child {
        padding-right: var(--spacing-xs) !important;
      }

      &[colspan] {
        border-top: none !important;
      }

      &.cell--icons {
        @extend %actions-icon;

        text-align: right;
        white-space: nowrap;
        font-weight: 400;
      }
    }

    > th {
      text-transform: capitalize;
    }
  }

  > thead > tr {
    font-weight: 700;
    background-color: var(--clr-table-th-bg);
  }

  &.table--force-mobile {
    > * > tr {
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(10rem, 1fr)) !important;
      grid-column-gap: var(--spacing-m);
      grid-row-gap: var(--spacing-m);
      font-weight: 700;

      &.rowspan {
        font-weight: 400;
      }
    }

    > thead > tr {
      display: none;
    }

    > tbody > tr {
      border-top: 1px solid var(--clr-table-border);
      padding: var(--spacing-m) var(--spacing-none) var(--spacing-s) var(--spacing-none);

      &.rowspan {
        border-top: none !important;
        padding-top: calc(var(--spacing-m) - var(--spacing-s)); /* grid-row-gap minus padding-bottom of tr above */
      }

      > td,
      > th {
        display: block;
        border-top: none;
        padding: var(--spacing-none) !important;

        &.cell--empty {
          color: var(--clr-silver) !important;
        }

        &.cell--icons {
          grid-row-end: 1;
          grid-column: 1 / -1;
          justify-self: end;
        }

        &[colspan] {
          grid-column: 1 / -1;
        }

        &:before {
          content: attr(data-title);
          font-size: 12px;
          display: block;
          font-weight: 400;
        }
      }
    }
  }
}

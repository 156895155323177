[data-style="2"] {
  /**
 * Avatars
 */

  .avatar {
    display: inline-block;
    vertical-align: middle;
    background-size: cover;
    border-radius: 0.25rem;
    overflow: hidden;
    line-height: 1;
    color: white;
    width: 100%;
    height: 100%;

    img {
      max-width: 100%;
      max-height: 100%;
    }
  }

  .avatar--custom-dimensions {
    img {
      border-radius: unset !important;
      max-width: unset;
      max-height: unset;
    }
  }

  .avatar--fixed-40px {
    width: 40px;
    height: 40px;
  }

  .avatar--fixed-38px {
    width: 38px;
    height: 38px;
  }

  .avatar--fixed-24px {
    width: 25px;
    height: 25px;
  }

  .avatar--fixed-16px {
    width: 16px;
    height: 16px;
  }


  /*
  BOR-TODO if we have an avatar--with-border then the width is determined by the container,
  which is not how the rest of the avatars work (they set their own size)
  */
  .avatar--with-border {
    border-width: 2px;
    border-style: solid;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    margin: 2px;
  }

  .avatar--circle {
    border-radius: 50%;

    div, img {
      border-radius: 50%;
    }
  }

  .avatar-placeholder {
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;

    > i, > div {
      display: block;
      font-weight: 700;
    }

    /* By adding the class 'avatar-placeholder--module_name, for example, avatar-placeholder--people, the avatar placeholder background color is aligned with the moduel color */
    @each $module in var(--modules) {

      &.avatar-placeholder--$(module), &.avatar-placeholder--$(module):hover, &.avatar-placeholder--$(module) > div {
        background-color: var(--clr-$(module)-primary) !important;
      }

      &.avatar-placeholder--$(module).avatar--with-border {
        box-shadow: 0 0 0 2px var(--clr-$(module)-primary);
      }
    }
  }
}

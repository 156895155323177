[data-style="2"] {

/**
 * Forms, heavily inspired by bootstrap and carbondesign
 *
 *
 */

  .form--highlighted-labels > .form-group > label:first-of-type {
    font-weight: 700;
    font-family: Roboto, sans-serif !important;
    color: var(--clr-text);
    margin-bottom: var(--spacing-xxl);
  }

  .form-group > div > .form-group__icons i {
    margin-left: var(--spacing-xxs) !important;
    margin-top: var(--spacing-m) !important;
  }

.form-group--search {
  position: relative;

  input {
    float: left;
    padding-left: calc(2*var(--spacing-xxl));
  }

  .fa-search {
    position: absolute;
    top: 11px;
    left: 12px;
  }
}

.form-check--inline {
  display: inline-flex;
  align-items: center;
}

.form--inline .form-group, .form--inline-15 .form-group {
  > span {
    text-align: left;
  }

  > label:first-child {
    margin-right: var(--spacing-xs);
  }

  > div:last-child, > select:last-child, > input:last-child, > textarea:last-child {
    display: flex !important;
    flex-direction: row;
  }

  > div > .form-group__icons {
    display: flex;
    flex-direction: row;
  }

  > div > .form-group__icons.form-group__icons--column {
    flex-direction: column-reverse;
    align-self: self-end;
  }

  > div > .form-group__icons i {
    margin-top: var(--spacing-m);
    margin-left: var(--spacing-xxs) !important;
  }
}

@media (--large) {
  .form--inline .form-group {
    display: flex;
    justify-content: space-between;

    > label:first-child:not(.btn) {
      margin-top: var(--spacing-xs);
      width: 40% !important;
    }

    > div:last-child, > select:last-child, > input:last-child, > textarea:last-child {
      width: 60% !important;
    }
  }
}

@media (--large) {
  .form--inline-15 .form-group {
    display: flex;
    justify-content: space-between;

    > label:first-child:not(.btn) {
      margin-top: var(--spacing-xs);
      width: 15% !important;
    }

    > div:last-child, > select:last-child, > input:last-child, > textarea:last-child {
      width: 85% !important;
    }
  }
}

/* used in help texts */
.form-text {
  display: block;
  margin-top: var(--spacing-xxs);
  padding-left: var(--spacing-xs);
}

/* put asterisk (*) before any required element */
.required:before {
  content:"* ";
  color: var(--clr-alizarin-crimson);
}


/* Eventival checkbox styling */
.form-check {
  padding-left: var(--spacing-xxs);
}

.tile .form-check {
  margin-left: 0;
  margin-bottom: var(--spacing-s);
}

.form-check-label { margin-bottom: var(--spacing-none); }


input[type=checkbox] { display:none; } /* to hide the checkbox itself */
input[type="radio"]{display:none;}


.form-check-input {
  cursor: pointer;
  font-family: var(--family-sans);
  position: absolute;
  opacity: 0;

  & + label {
    display: flex;
    position: relative;
    cursor: pointer;
    user-select: none;
    margin-bottom: var(--spacing-none);
  }

  &.form-check-input--no-reply + label {
    font-style: italic;

    &:before {
      font-style: normal;
    }
  }

  /* Box */
  & + label:before {
    font-family: var(--family-awesome);
    font-weight: 300; /* use light styles */
    content: '';
    margin-right: var(--spacing-xxs);
    margin-left: var(--spacing-xxxs);
    display: inline-block;
    vertical-align: text-top;
    transition: color 0.25s ease-in-out;
  }

  /* Box focus */
  &:focus + label:before {
    box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
    outline: 0;
  }

  /* Disabled */
  &:disabled + label {
    box-shadow: none;
    cursor: not-allowed;
    color: var(--clr-disabled);
  }
}

/* font awesome icons for checkbox and radios */
input[type=checkbox].form-check-input +label:before { content: '\f0c8';}
input[type=checkbox].form-check-input:checked +label:before { content: '\f14a';}
input[type=radio].form-check-input +label:before { content: '\f111';}
input[type=radio].form-check-input:checked +label:before { content: '\f058';}

.form-check-input:checked + label:before {
  font-weight: 900;
  color: var(--clr-dove-gray);
}

.form-check-input:checked {
  &.color-success-dark + label:before {
    color: var(--clr-lime) !important;
  }
}

/* ranges */
.form-control-range {
  display: block;
  width: 100%;
}

.form-row {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  flex-wrap: wrap;
  font-stretch: condensed;

  span {
    margin-top: var(--spacing-xl);
  }
}

.form-control-plaintext:focus {
  -webkit-box-shadow: unset !important;
  box-shadow: unset !important;
  border: solid 1px #eaeaea !important;
}


@media(--hover) {
  .form-control-plaintext:hover {
    -webkit-box-shadow: unset !important;
    box-shadow: unset !important;
    border: solid 1px #eaeaea !important;
  }
}

.elastic-grid--horizontal-30 textarea, .elastic-grid--horizontal-20 textarea, .elastic-grid--horizontal-12 textarea, .elastic-grid--vertical textarea {
  resize: vertical;
}

}

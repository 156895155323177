[data-style="2"] {
  .multilevel-select {
    &-list {
      &--mh200 {
        /* @TODO: to be replaced by automated counting of max-heigth related to the screen size */
        max-height: 200px;
        overflow-y: auto;
        scroll-behavior: smooth;
      }

      &--mh400 {
        /* @TODO: to be replaced by automated counting of max-heigth related to the screen size */
        max-height: 400px;
        overflow-y: auto;
        scroll-behavior: smooth;
      }

      &--mh80vh {
        /* @TODO: to be replaced by automated counting of max-heigth related to the screen size */
        max-height: 80vh;
        overflow-y: auto;
        scroll-behavior: smooth;
      }

      &--mh20vh {
        /* @TODO: to be replaced by automated counting of max-heigth related to the screen size */
        max-height: 20vh;
        overflow-y: auto;
        scroll-behavior: smooth;
      }
    }

    &-item-hidden {
      height: 0 !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
      overflow: hidden;
    }
  }
}

/* Typography

Here we will set all default paddings, margins, font-size etc.

*/

html {
  height: 100%;

  font-size: 100%; /* This means 16px */
  font-size: 16px; /* This means 16px */

  /*@media (--medium) {
    font-size: 16px;
  }*/
}

body {
  margin: 0;
  font-weight: 400;
  line-height: var(--baseline-height);  /* defined for postcss-vertical-rhythm-function plugin */
  font-size: calc(0msu * 1rem);
  color: var(--clr-text);
}

[data-style="2"] {

  code, pre {
  font-family: "source-code-pro",Consolas,"Andale Mono WT","Andale Mono","Lucida Console","Lucida Sans Typewriter","DejaVu Sans Mono","Bitstream Vera Sans Mono","Liberation Mono","Nimbus Mono L",Monaco,"Courier New",Courier,monospace;
  white-space: pre-wrap;
  border-radius: var(--base-radius);
  padding: var(--spacing-xxs);
}

code {
  background-color: var(--clr-athens-gray);
  color: var(--clr-mandy);
  word-break: break-word;
}

span {
  font-size: calc(0msu * 1rem);
}

pre {
  display: block;
  padding: var(--spacing-xl);
  margin: var(--spacing-xxs);
  margin-bottom: var(--spacing-none);
}


label {
  color: var(--clr-gray);
  margin-bottom: var(--spacing-none);
  font-family: var(--family-condensed);
}

h1,h2,h3,h4,h5,h6,
.h1,.h2,.h3,.h4,.h5,.h6 {
  font-family: var(--family-heading);
  color: var(--clr-text);
  font-weight: 700;
  text-decoration: none;
}

h1,
.h1 {
  font-size: calc(8msu * 1rem);
  font-family: var(--family-sans);
}

h2,
.h2 {
  font-size: calc(4msu * 1rem);
}

h3,
.h3 {
  font-size: 18px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: calc(1msu * 1rem);
}

h6,
.h6 {
  font-size: calc(0msu * 1rem);
}

.card h1, .card h2, .card h3, .card h4, .card h5, .card h6 {
  padding-top: var(--spacing-none) !important;
}

.card h4.toggle, .card h5.toggle, .card h6.toggle {
  padding-top: var(--spacing-xs) !important;;
}

p,
.p {
  font-size: calc(0msu * 1rem);
  margin-bottom: var(--spacing-xs);

  &.wide { @extend %measure-wide; }
}



ol,
ul {
  font-size: calc(1msu * 1rem);
}

a {
  color: var(--clr-action);
  text-decoration: none;
}

@media(--hover) {
  a:hover {
    text-decoration: underline;
  }
}

blockquote {
  padding: 0 var(--spacing-l);
  color: #6a737d;
  border-left: 0.25em solid var(--clr-border);
}


article {
  padding: var(--spacing-l);
}

hr {
  background-color: var(--clr-border);
  height: 1px;
  border: 0;
  display: block; /* TODO: old_style_compatibility - remove this line when we stop using old css at BO */
  margin-bottom: var(--spacing-xl);
  margin-top: var(--spacing-none) !important;
}

.hr-thin-3 {
  height: 3px;
}

address {
  font-style: normal;
  display: inline-block;
}

sup {
  font-size: 65%;
}

.anchor {
  text-decoration: none;
  color: var(--clr-action);
  cursor: pointer;

  i {
    padding-right: var(--spacing-xxs);
  }
}

@media(--hover) {
  .anchor:hover {
    text-decoration: underline;
  }
}

i.fa, i.far, i.fas, i.fal {
  cursor: pointer;
}

input {
  font-family: var(--family-sans);
}

input:not([type=submit]), select, .select-selected, .input {
  height: calc(var(--baseline-height)rem + var(--spacing-s) * 2);
}

/* disabled inputs */
input[readonly].readonly, input[readonly].readonly:focus, select[readonly].readonly, select[readonly].readonly:focus, select[readonly].readonly:focus, .select-selected[readonly].readonly, .select-selected[readonly].readonly:focus, textarea[readonly].readonly, textarea[readonly].readonly:focus {
  background-color: var(--clr-disabled-bg);
  opacity: 1;
}

input:not([type=submit]), select, textarea, .select-selected, .input {
  display: block;
  width: 100%;
  color: var(--clr-dove-gray);
  border: 1px solid var(--clr-alto);
  border-radius: var(--spacing-xxs);
  padding: var(--spacing-s);
  background-color: var(--clr-white);
  font-size: 14px !important;

  &:hover,
  &:focus {
    box-shadow: 0 2px 3px 0 var(--clr-french-pass);
    border: solid 1px var(--clr-french-pass);
  }

  @media(--hover) {
    &:hover {
      box-shadow: 0 2px 3px 0 var(--clr-french-pass);
      border: solid 1px var(--clr-french-pass);
    }
  }

  &:focus {
    outline: 0;
    background-color: var(--input-clr-focus-bg);
    border-color: var(--input-clr-focus-border);
    box-shadow: var(--input-focus-box-shadow);
  }
}

  /*** iPhone and iOS Form Input Zoom Fixes ***/

  /* Fix Input Zoom on devices older than iPhone 5: */
  @media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
  }

  /* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
  @media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
  }

  /* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
  @media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
  }

  /* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
  @media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px !important; }
  }
}

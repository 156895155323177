[data-style="2"] {
  .toggle {
    cursor: pointer;
    display: flex;
    flex-wrap: nowrap;
  }

  .toggle-content {
    overflow: hidden;
    transition: max-height 0.5s ease-in-out;

    a {
      display: block;
    }

    @media(--hover) {
      li a:hover {
        text-decoration: none;
      }
    }

    &:not(.toggle-content--default-open) {
      max-height: 0;
    }
  }

  .toggle-content--display {
    &:not(.toggle-content--default-open, .is-open) {
      display: none !important;
    }
  }

  li.toggle,
  .toggle--list > li,
  .toggle--sublist > li {
    border-top: 3px solid var(--clr-white);
    padding: var(--spacing-xxs) var(--spacing-xs);
  }

  li.toggle {
    background-color: var(--clr-athens-gray) !important;
  }

  .toggle--list > li,
  .toggle--sublist > li {
    cursor: pointer;
  }

  .toggle--list > li {
    margin-left: var(--spacing-xxxl) !important;
  }

  .toggle--sublist > li {
    margin-left: calc(2 * var(--spacing-xxxl)) !important;
  }

  .toggle--sublist > li:hover {
    @media (--hover) {
      color: var(--clr-action);
    }
  }

  .toggle--list > li {
    background-color: var(--clr-black-5) !important;
  }

  .toggle--sublist > li {
    background-color: var(--clr-alto) !important;
  }

  .toggle {
    &.icon-before::before,
    &.icon-after::after,
    &.checkbox-before::before {
      font-family: var(--family-awesome);
      line-height: inherit;
    }

    &.checkbox-before {
      display: flex;

      &::before {
        content: "\f0c8";
        margin-right: var(--spacing-xxs);
        margin-left: var(--spacing-xxxs);
        display: inline-block;
        vertical-align: text-top;
        transition: color 0.25s ease-in-out;
      }

      &.is-open::before {
        content: "\f14a";
        font-weight: 900;
        color: var(--clr-dove-gray);
      }
    }

    &.arrow-caret.icon-before::before,
    &.arrow-caret.icon-after::after {
      content: "\f0d7";
      font-weight: bold;
    }

    &.plus.icon-before::before,
    &.plus.icon-after::after {
      content: "\f055";
      font-weight: bold;
      text-decoration: none;
      font-size: 93%;
    }

    &.plus.is-open.icon-before::before,
    &.plus.is-open.icon-after::after {
      transform: unset;
    }

    &.icon-before::before, &.icon-after.is-open::after {
      padding-right: var(--spacing-xxs);
      padding-left: var(--spacing-none);
    }

    &.icon-after::after, &.icon-before.is-open::before {
      padding-left: var(--spacing-xxs);
      padding-right: var(--spacing-none);
    }

    &.arrow-caret--right.icon-before::before,
    &.arrow-caret--right.icon-after::after,
    &.arrow-caret--left.icon-before::before,
    &.arrow-caret--left.icon-after::after {
      content: "\f0d7";
      font-weight: bold;
    }

    @media (--wide) {
      &.arrow-caret--right.icon-before::before,
      &.arrow-caret--right.icon-after::after {
        content: "\f0da";
      }

      &.arrow-caret--left.icon-before::before,
      &.arrow-caret--left.icon-after::after {
        content: "\f0d9";
      }

      &.arrow-caret--right.icon-after {
        display: flex;
        justify-content: space-between;
      }
    }

    &.hamburger.icon-before::before,
    &.hamburger.icon-after::after {
      content: "\f0c9";
      position: absolute;
      top: 5px;
      right: 8px;
    }

    &.hamburger.is-open.icon-before::before,
    &.hamburger.is-open.icon-after::after {
      content: "\f00d";
      position: absolute;
      right: 5px;
      top: 5px;
      transform: rotate(-90deg);
    }

    &.arrow-angle.icon-before::before,
    &.arrow-angle.icon-after::after {
      content: "\f107";
    }

    &.arrow-angle-right.icon-before::before,
    &.arrow-angle-right.icon-after::after {
      content: "\f105";
    }

    &.arrow-angle.icon-after::after,
    &.arrow-angle.icon-before::before {
      margin-left: var(--spacing-none) !important;
    }

    &.is-open.icon-after::after,
    &.is-open.icon-before::before {
      display: inline-block;
      -webkit-transform: rotate(-180deg); /* WebKit */
      -moz-transform: rotate(-180deg); /* Mozilla */
      -o-transform: rotate(-180deg); /* Opera */
      -ms-transform: rotate(-180deg); /* Internet Explorer */
      transform: rotate(-180deg);
    }

    &.icon-after::after,
    &.icon-before::before {
      font-family: var(--family-awesome);
      line-height: inherit;
      cursor: pointer;
      margin: 0;
    }

    &.fa-thumbtack.is-open {
      font-weight: 900;
    }
  }

  .multilevel-dropdown {
    .dropdown {
      padding-left: var(--spacing-xxl);

      .dropdown {
        padding-left: var(--spacing-xxl);

        .toggle {
          padding-left: var(--spacing-xxl);
        }

        .toggle-content {
          width: max-content;
        }
      }
    }
  }

  @media (--wide) {
    .multilevel-dropdown {
      .dropdown {
        padding-left: var(--spacing-none);

        .dropdown {
          padding-left: var(--spacing-none);

          .toggle {
            padding-left: var(--spacing-none);
          }
        }
      }
    }
  }

  .dropdown,
  .all-media-dropdown {
    position: relative;

    &.dropdown--bottom-left > .toggle-content {
      right: 0;
      left: unset;
    }

    @media (--wide) {
      &.dropdown--bottom-left--desktop > .toggle-content {
        right: 0;
        left: unset;
      }
    }
  }

  /* forcing dropdown on mobile */
  @define-mixin toggle-dropdown-content {

    background-color: var(--clr-white);
    min-width: 150px;
    max-width: 300px;
    max-height: none;
    display: none;
    position: absolute;
    -webkit-box-shadow: -1px 10px 11px 3px rgba(0, 0, 0, 0.16);
    -moz-box-shadow: -1px 10px 11px 3px rgba(0, 0, 0, 0.16);
    box-shadow: -1px 10px 11px 3px rgba(0, 0, 0, 0.16);
    z-index: var(--z-index-dropdown);
    overflow: visible;
    text-align: left;

    hr {
      margin-bottom: var(--spacing-none);
    }

    &.is-open {
      display: block !important;
    }

    .li--highlighted,
    .active {
      background-color: var(--clr-gallery);
    }

    li {
      .toggle {
        margin-bottom: var(--spacing-none) !important;
      }

      &:hover {
        @media (--hover) {
          background-color: var(--clr-gallery);
        }
      }

      a,
      > div:first-child,
      span {
        color: var(--clr-text);
        padding: var(--spacing-m);
        display: flex;
        line-height: 1.2;
        cursor: pointer;
      }
    }
  }

  @media (--wide) {
    .dropdown {
      > .toggle-content {
        @mixin toggle-dropdown-content;
      }

      &.dropdown--bottom-left > .toggle-content,
      &.dropdown--bottom-left--desktop > .toggle-content {
        right: 0;
        left: unset;
      }

      &.dropdown--bottom-right > .toggle-content {
        left: 0;
        right: unset;
      }

      &.dropdown--top-left > .toggle-content {
        right: 0;
        left: unset;
        bottom: 100% !important; /* BOR-todo: added important due to bor_conflict.css */
        top: unset !important;
      }

      &.multilevel-select {
        padding: var(--spacing-m);
      }

      &.dropdown--right {
        li {
          display: block;
        }

        > .toggle {
          padding: var(--spacing-m);
          line-height: 1;
        }

        > .toggle-content {
          position: absolute;
          left: 100%;
          top: 0;
        }
      }

      &.dropdown--left {
        li {
          display: block;
        }

        > .toggle {
          padding: var(--spacing-m);
          line-height: 1;
        }

        > .toggle-content {
          position: absolute;
          right: 100%;
          top: 0 !important;
        }
      }
    }
  }

  .dropdown-with-border--small > .toggle {
    border: 2px solid var(--clr-silver);;
    border-radius: 3px;
    padding: var(--spacing-xxs) var(--spacing-s);
  }

  .all-media-dropdown.dropdown {
    > .toggle-content {
      @mixin toggle-dropdown-content;
    }
  }

  &.toggle-content--dropdown-standalone,
  .toggle-content--dropdown-standalone {
    @mixin toggle-dropdown-content;

    position: absolute;
    z-index: var(--z-index-highest);
  }
}

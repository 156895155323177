
/*

  =====================================
  colors.css - definiton of used colors
  =====================================


  To prevent usage of colors such as gray, gray1, gray_darker and so on
  we use chir.ag/projects/name-that-color/ to give the color proper name.


  Initial inspiration taken from http://sass-lang.com/styleguide/color

 */

:root {
  /* eventival extracted and approved colors */
  --clr-black: #000000;
  --clr-black-5: color(var(--clr-black) lightness(95%)); /*#F2F2F2*/
  --clr-black-10: color(var(--clr-black) lightness(90%));
  --clr-scorpion: #585858;
  --clr-gray: #888888;
  --clr-dove-gray: #6b6b6b;
  --clr-dusty-gray: #9c9c9c;
  --clr-silver: #bebebe;
  --clr-alto: #D9D9D9;
  --clr-mercury: #e6e6e6;
  --clr-gallery: #eaeaea;
  --clr-athens-gray: #f8f9fa;
  --clr-lochmara: #0386bc;
  --clr-cerulean: #009fe3;
  --clr-cerulean-10: color(var(--clr-cerulean) lightness(90%)); /*#CCF0FF*/
  --clr-picton-blue: #55c0ed;
  --clr-zircon: #eaefff;
  --clr-onahau: #ccf0ff;
  --clr-french-pass: #c6e9fc;
  --clr-tahuna-sands: #E6F2CE;
  --clr-lime: #80B915;
  --clr-conifer: #A6D350;
  --clr-christi: #70A015;
  --clr-salem: #0fa83e;
  --clr-malachite: #12d24e;
  --clr-magic-mint: #9DF6B9;
  --clr-creme-brulee: #ffd9a0;
  --clr-fire-bush: #e59b28;
  --clr-sunshade: #ffad2d;
  --clr-sunshade-10: color(var(--clr-sunshade) lightness(90%));
  --clr-orange-peel: #FF9B00;
  --clr-sandy-beach: #ffebcc;
  --clr-varden: #fff6da;
  --clr-white: #fff;
  --clr-thunderbird: #B91521;
  --clr-alizarin-crimson: #db2633;
  --clr-mandy: #EA656E;
  --clr-cupid: #fcc6c6;
  --clr-hawaiian-tan: #974714;
  --clr-hot-cinnamon: #d8651e;
  --clr-mandys-pink: #f3c3a7;
  --clr-indigo: #4F69C6;
  --clr-spindle: #cad1ee;
  --clr-sapphire: #304796;

  /* Eventival 3 colors */

  --clr-emperor: #535353; /* header, text */
  --clr-tundora: #414141; /* search */


  /* 3rd party colors */
  --clr-google: #F53F2B; /* Pomegranate */
  --clr-facebook: #4C679F; /* Kashmir Blue */
  --clr-twitter: #40ACDD; /* Picton Blue */

  /* TODO: incorporate */

  --clr-silver-chalice: #a9a9a9;
  --clr-dove-gray-darker: #707070;
  --clr-buttercup: #f6a21e;
  --clr-buttermilk: #fef1ba;
  --clr-texas-rose: #febd55;
  --clr-golden-grass: #db9c33;
  --clr-woodsmoke: #171b1e;
  --clr-steel-blue: #479aba;
  --clr-lily-white: #e9f8ff;

  /* sample defined color-aliases */

}

/* Definition of colors for background */
@mixin bgcolor black;
@mixin bgcolor scorpion;
@mixin bgcolor athens-gray;
@mixin bgcolor gray;
@mixin bgcolor dove-gray;
@mixin bgcolor black-5;
@mixin bgcolor black-10;
@mixin bgcolor cerulean;
@mixin bgcolor zircon;
@mixin bgcolor lochmara;
@mixin bgcolor onahau;
@mixin bgcolor french-pass;
@mixin bgcolor tahuna-sands;
@mixin bgcolor lime;
@mixin bgcolor conifer;
@mixin bgcolor christi;
@mixin bgcolor malachite;
@mixin bgcolor magic-mint;
@mixin bgcolor cupid;
@mixin bgcolor silver;
@mixin bgcolor mercury;
@mixin bgcolor gallery;
@mixin bgcolor creme-brulee;
@mixin bgcolor sunshade;
@mixin bgcolor orange-peel;
@mixin bgcolor fire-bush;
@mixin bgcolor hawaiian-tan;
@mixin bgcolor hot-cinnamon;
@mixin bgcolor mandys-pink;
@mixin bgcolor sandy-beach;
@mixin bgcolor varden;
@mixin bgcolor white;
@mixin bgcolor thunderbird;
@mixin bgcolor alizarin-crimson;
@mixin bgcolor mandy;
@mixin bgcolor picton-blue;
@mixin bgcolor indigo;
@mixin bgcolor spindle;
@mixin bgcolor sapphire;
@mixin bgcolor malachite;

/* Border colors */
@mixin border-color black;
@mixin border-color scorpion;
@mixin border-color gray;
@mixin border-color dove-gray;
@mixin border-color cerulean;
@mixin border-color onahau;
@mixin border-color french-pass;
@mixin border-color tahuna-sands;
@mixin border-color lime;
@mixin border-color conifer;
@mixin border-color christi;
@mixin border-color cupid;
@mixin border-color silver;
@mixin border-color alto;
@mixin border-color mercury;
@mixin border-color gallery;
@mixin border-color athens-gray;
@mixin border-color creme-brulee;
@mixin border-color sunshade;
@mixin border-color fire-bush;
@mixin border-color sandy-beach;
@mixin border-color varden;
@mixin border-color white;
@mixin border-color thunderbird;
@mixin border-color alizarin-crimson;
@mixin border-color mandy;
@mixin border-color tundora;

/* Text colors */
@mixin color black;
@mixin color scorpion;
@mixin color gray;
@mixin color dove-gray;
@mixin color cerulean;
@mixin color onahau;
@mixin color french-pass;
@mixin color tahuna-sands;
@mixin color lime;
@mixin color conifer;
@mixin color christi;
@mixin color cupid;
@mixin color silver;
@mixin color alto;
@mixin color mercury;
@mixin color gallery;
@mixin color athens-gray;
@mixin color creme-brulee;
@mixin color sunshade;
@mixin color fire-bush;
@mixin color sandy-beach;
@mixin color varden;
@mixin color white;
@mixin color thunderbird;
@mixin color alizarin-crimson;
@mixin color mandy;

@each $module in var(--modules) {
  [data-module-current="$(module)"] {
    .bg-module {
      background: var(--clr-$(module)-ternary) !important;
    }
  }
}

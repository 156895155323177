[data-style="2"] {
  
/** Shadows */
@define-mixin box-shadow $shadow {
    box-shadow: $shadow;
}

}

%shadow--1dp { 
  box-shadow: 0 0 1px 1px rgba(0,0,0,.12) !important;;
}

%shadow--2dp { 
  box-shadow: 0 0 2px 2px rgba(0,0,0,.12) !important;;
}

%shadow--3dp { 
  box-shadow: 0 0 3px 3px rgba(0,0,0,.12) !important;;
}
/**
@define Button

 For buttons we support following classes and tags `button, .btn`

*/

:root {
  --Button-border-width: 2px;
  --Button-color: inherit;
  --Button-disabled-opacity: 0.6;
  --Button-font: inherit;
  --Button-transition: all .15s ease-in-out;
  --Button-padding: var(--spacing-m) var(--spacing-xxl);
  --Button-border-radius: 3px;
  --Button-outline-padding: calc(var(--spacing-m) - 2px) var(--spacing-xxl);
}

[data-style="2"] {

.btn {
  background: unset;
  border: none;
  box-sizing: border-box;
  border-radius: var(--Button-border-radius);
  color: var(--clr-white);
  display: inline-block;
  padding: var(--Button-padding);
  position: relative;
  text-align: center;
  text-decoration: none;
  user-select: none;
  line-height: 1.1;
  cursor: pointer;
  transition: var(--Button-transition);

  &::-moz-focus-inner {
    border: 0;
    padding: var(--spacing-none);
  }

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }

  &:focus {
    outline: 0 !important;
  }

  .is-disabled,
  &:disabled {
    cursor: default;
    opacity: var(--Button-disabled-opacity);
  }
}

.btn {
  &textarea:focus,
  &input:focus {
    outline: 0 !important;
  }

  &--small {
    font-size: .8em;
    padding: var(--spacing-xs) var(--spacing-xl);
  }

  &--medium {
    font-size: 1em;
    padding: var(--spacing-s) var(--spacing-l);
  }

  &--large {
    font-size: 1.2em;
    padding: var(--spacing-xl) var(--spacing-xl);
  }

  &--primary,
  &--secondary,
  &--action,
  &--danger {
    &-outline {
      border-width: var(--Button-border-width);
      border-style: solid;
      background-color: unset;
      padding: var(--Button-outline-padding);

      @media(--hover) {
        &:hover {
          color: var(--clr-white);
        }
      }

      &:active {
        color: var(--clr-white);
      }
    }
  }

  @each $variant, $outline-border-suffix in (primary, secondary, action, danger), ('', -light, -light, '') {
    &--$(variant) {
      background-color: var(--clr-$(variant));

      @media(--hover) {
        &:hover {
          background-color: var(--clr-$(variant)-hover);
        }
      }

      &:active {
        background-color: var(--clr-$(variant)-active);
      }

      &-outline {
        border-color: var(--clr-$(variant)$(outline-border-suffix));
        color: var(--clr-$(variant));

        @media(--hover) {
          &:hover {
            background-color: var(--clr-$(variant));
            border-color: var(--clr-$(variant));
          }
        }

        &:active {
          background-color: var(--clr-$(variant)-active);
        }
      }
    }
  }
}

.btn-photo-group {
  display: flex;
  flex-direction: row;
  justify-content: center;
  border: 1px dashed var(--clr-gallery);
  padding: var(--spacing-xl);
}
}

[data-style="2"] {
  ul,
  ul& {
    list-style-type: none;
    list-style-position: outside;
  }

  ol,
  ul,
  ol&,
  ul& {
    margin: var(--spacing-none);
    padding: var(--spacing-none);

    li {
      line-height: 1.7;

      &.li {
        &--bullet {
          padding-left: var(--spacing-l);
          position: relative;

          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            font-size: calc(-9msu * 1rem);
            padding-right: var(--spacing-l);
            font-family: var(--family-awesome);
            font-weight: 700;
            content: "\f111";
          }
        }


        /** list items modifications */

        &--modified {
          padding-left: var(--spacing-xl);
          position: relative;

          &:before {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            display: flex;
            align-items: center;
            font-size: calc(-6msu * 1rem);
            padding-right: var(--spacing-l);
            font-family: var(--family-awesome);
            font-weight: 700;
            content: "\f111";
            color: var(--clr-modified);
          }
        }

        &--disabled {
          color: var(--clr-disabled);
        }
      }
    }

    &.list--bullets {
      list-style-type: disc;
      margin: inherit;

      li {
        margin-left: calc(2*var(--spacing-l));
      }
    }
  }

  /* BOR-todo: delete if not used */
  dl dt {
    font-weight: bold;
    font-size: 1.125rem;
  }
  dd {
    margin: var(--spacing-xs) var(--spacing-none) var(--spacing-xl) var(--spacing-none);
    padding: var(--spacing-none);
  }
}

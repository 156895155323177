[data-style="2"] {

/* selects */
/*the container must be positioned relative:*/
/* The container must be positioned relative: */
.custom-select {
  position: relative;
  font-size: 13.3333px;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: var(--clr-white);
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 5px solid transparent;
  border-color: var(--clr-text) transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent var(--clr-text) transparent;
  top: 7px;
}

/* Style items (options): */
.select-items {
  background-color: var(--clr-white);
  z-index: var(--z-index-dropdown);
  border-left: 1px solid var(--clr-gallery);
  border-right: 1px solid var(--clr-gallery);
  border-bottom: 1px solid var(--clr-gallery);
  max-height: 150px;
  overflow: auto;

  div {
    padding: var(--spacing-xs);
  }
}

  /* Hide the items when the select box is closed: */
  .select-hide {
    display: none;
  }

  .same-as-selected {
    background-color: rgba(0, 0, 0, 0.1);
  }

  @media(--hover) {
    .select-items div:hover {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

a.ev-login-button {
	display: inline-flex;
	width: auto;
	min-width: 80px;
	min-height: 30px;
	background: #06b8e7;
	border-radius: 3px;
	cursor: pointer;
	font: 14px/16px Arial;
	color: #fff;
	text-decoration: none;
	box-shadow: 0 2px 0 #ccc;
}

a.ev-login-button::before {
	min-height: 40px;
	min-width: 40px;
	border-radius: 3px 0 0 3px;
	content: "";
	background: url("/downloads/ea_logo_no_background.png") center center no-repeat #fff;
	background-size: 30px 30px;
}

a.ev-login-button > span {
	padding: 12px 25px;
	align-self: center;
}

a.ev-login-button:hover {
	background-color: #06c1f3;
	text-decoration: none;
}

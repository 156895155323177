[data-style="2"] {
  .tag {
    @extend %fs-2;
    display: inline-block;
    padding: var(--spacing-xxxs) var(--spacing-xs);
    border-radius: var(--base-radius);
    text-align: center;
    background-color: var(--clr-black-5);
    white-space: nowrap;
    box-shadow: 1px 1px 1px rgba(0, 0, 0, 0.14);
    border: 1px solid var(--clr-gallery);
    cursor: pointer;
    line-height: 1.4 !important;
    font-weight: 400 !important;

    a {
      text-decoration: none;
      color: var(--clr-text);
    }

    @media(--hover) {
      &:hover {
        background-color: var(--clr-gallery);
      }

      a:hover {
        text-decoration: underline;
      }
    }

    &--label,
    &--sub {
      box-shadow: unset;
      border: none;
      padding: calc(1px + var(--spacing-xxxs)) var(--spacing-xs);
      cursor: default;

      @media(--hover) {
        &:hover {
          background-color: var(--clr-black-5);
        }
      }
    }

    &--sub {
      @extend %fs-1;
      padding: 0 5px;
      border-radius: 10px 10px 10px 0;
    }

    &--inline,
    &--inline-container {
      margin-top: calc(-1 * var(--spacing-xxxs));
    }

    &--inline {
      margin-bottom: calc(2 * var(--spacing-xxxs));
    }

    &:hover {
      @media(--hover) {
        box-shadow: unset;
      }
    }
  }

  .tags--multiple {
    border: 2px solid var(--clr-mercury);
    position: relative;
    border-radius: 20px;
    background-color: var(--clr-athens-gray);
    padding: var(--spacing-xxxs);
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--spacing-none) var(--spacing-xxs);

    .searchable {
      border-radius: 20px;
      background-color: var(--clr-athens-gray);

      &:not(:first-child) {
        @extend %ml7;
      }

      &:hover {
        background: var(--clr-gallery);
      }

      &:not(:last-child):after {
        content: "";
        position: absolute;
        top: 1px;
        right: -8px;
        width: 22px;
        height: 22px;
        display: inline-flex;
        border: 2px solid;
        border-color: var(--clr-mercury) transparent transparent var(--clr-mercury);
        transform: rotate(135deg);
      }
    }
  }
}

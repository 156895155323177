/** postcss-extend declaration */
%tab-with-top-border {
  content: " ";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border: 2px solid var(--clr-gray);
}

@each $module in var(--modules) {
  [data-module-current="$(module)"] [data-style="2"] .tab.active:before,
  [data-module-current="$(module)"] [data-style="2"] .tab:hover:before {
    border: 2px solid var(--clr-$(module)-primary) !important;
  }
}

[data-style="2"] {

.tabs {
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  overflow-x: auto;

  .tab {
    position: relative;
    white-space: nowrap;
    color: var(--clr-text);
    cursor: pointer;
    user-select: none;
    text-transform: capitalize;
    background-color: var(--clr-white);
    padding: var(--spacing-l) var(--spacing-xxxl);
    border: 1px solid var(--clr-gallery);
    border-right: 0;

    > * {
      display: block;
    }

    &:first-child {
      border-left: none;
    }

    &:last-child {
      border-right: none;
    }

    &.active,
    &:hover {
      text-decoration: none;

      &:before {
        @extend %tab-with-top-border;
      }
    }
  }

  /* bordered customization */
  &.tabs--bordered,
  &.tabs--bordered--prolonged {

    .tab:last-child {border-right: 1px solid var(--clr-gallery);}
    .tab:first-child {border-left: 1px solid var(--clr-gallery);}
    .tab.active { border-bottom: 1px transparent; }
    .tab.active:before { @extend %tab-with-top-border; }
  }

  &.tabs--bordered > .tab:last-child() {
    border-right: 1px solid var(--clr-gallery);
  }

  &.tabs--bordered--prolonged::before,
  &.tabs--bordered--prolonged::after {
    border-top: 0 !important;
    content: "";
    cursor: initial;
    pointer-events: none;
    border-bottom: 1px solid var(--clr-gallery);
    align-items: flex-end;
    flex-basis: var(--spacing-xxxl);
  }

  &.tabs--bordered--prolonged::after {
    flex-grow: 1;
  }
}

}

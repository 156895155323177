.searchable, .profile-link, .non-clickable {
  border-radius: 3px;
  position: relative;
  cursor: pointer;
  display: inline-block;
  line-height: 1.4;
  font-weight: 400 !important;
  white-space: nowrap;

  > a,
  > span {
    color: var(--clr-text) !important;
    display: block;
    padding: 2px 5px;
    border-radius: 3px;
  }
}

.non-clickable {
  cursor: initial;
  background-color: var(--clr-black-5);
}

.tag-private {
  display: inline-flex;
}

.tag-private::before {
  content: "P";
  background-color: var(--clr-alto);
  padding: var(--spacing-none) var(--spacing-xs);
  border-top-left-radius: 3px;
  border-bottom-left-radius: 3px;
}

.searchable, .profile-link {
  > a,
  > span {
    position: relative;
  }
}

.searchable {
  background-color: var(--clr-black-5);
  border-radius: 12px;

  &:hover {
    background-color: var(--clr-gallery);
  }

  > a,
  > span {
    padding: 2px 8px;
    border-radius: 12px;
  }
}

.profile-link > a {
  display: inline;
}

@each $module-profile, $module in var(--modules-profiles) {
  .profile-link--$(module-profile) {
    background-color: var(--clr-$(module)-secondary);
    border: 1px solid var(--clr-$(module)-primary-light);

    &:hover {
      border-color: var(--clr-$(module)-text);
      text-decoration: none;
    }

    a {
      padding-top: 1px;
      padding-bottom: 1px;
      color: var(--clr-$(module)-text) !important;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.tag-editable {
  margin: var(--spacing-none) var(--spacing-xs) var(--spacing-xs) var(--spacing-none);
  display: flex;

  .searchable {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .deletable {
    padding: 5px 7px 3px 7px;
    background-color: var(--clr-alto);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .draggable {
    margin-right: 3px;
    font-size: 12px;
  }
}
